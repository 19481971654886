import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getDateAfterDays, getDateText } from '~/usecases/date';
import { styled } from 'styled-components';
import { COLORS, DEVICES, IMAGES } from '~/constants';
import { SpeechBallonBox } from '~/components/parts/speechBalloonsBox';
import { getAffiiateLink } from '~/usecases/link';
import { sendLinkClick } from '~/components/functions/analytics';
const generateButtonText = (vod, isStartingSoon) => {
    if (isStartingSoon) {
        return `${vod.name}でチェックする`;
    }
    else {
        return `今すぐ${vod.name}で観る`;
    }
};
const generateTopLabelText = (vod, isStartingSoon, freeTrialPeriod) => {
    if (isStartingSoon) {
        return 'まもなく配信';
    }
    // vod.name is fod.
    if (vod.id === 6) {
        return 'アプリダウンロード無料！';
    }
    // vod.name is video-market.
    if (vod.id === 3) {
        const lastDayOfMonth = getDateAfterDays(new Date(), 0);
        lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1, 0);
        return `本日から${getDateText(lastDayOfMonth, 'M月d日')}まで無料！`;
    }
    // vod.name is hikari-tv or watcha.
    if (vod.id === 36 || vod.id === 22) {
        const oneMonthLaterDate = getDateAfterDays(new Date(), 30);
        return `本日から${getDateText(oneMonthLaterDate, 'M月d日')}まで無料！`;
    }
    if (freeTrialPeriod) {
        const freeTrialEndDate = getDateAfterDays(new Date(), parseInt(freeTrialPeriod) - 1);
        return `本日から${getDateText(freeTrialEndDate, 'M月d日')}まで無料！`;
    }
    return '';
};
const PrimaryPlayButton = ({ widgetType, displaySideLabel, vod, distribution, isStartingSoon, freeTrialPeriod, }) => {
    const buttonText = generateButtonText(vod, isStartingSoon);
    const topLabelText = generateTopLabelText(vod, isStartingSoon, freeTrialPeriod);
    return (_jsx(ButtonWrapper, { children: _jsxs(ButtonLink, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                sendLinkClick(event.currentTarget.href, {
                    'widget_type': widgetType,
                    'vod_name': vod.name,
                });
            }, children: [topLabelText && _jsx(SpeechBallonBox, { labelText: topLabelText }), _jsxs(Paper, { children: [displaySideLabel && (_jsx(LeftLabel, { className: "leftLabel", children: _jsx(LeftLabelText, { children: "\u7121\u6599" }) })), _jsxs(ButtonBox, { children: [_jsx(VodLogoBox, { children: _jsx(VodLogoSquare, { src: vod.getSquareLogoImage(), alt: vod.name, width: 50, height: 50, onError: (event) => {
                                            event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                        } }) }), _jsx(MainText, { children: buttonText })] }), _jsx(ArrowForwardIconBox, { children: _jsx(ArrowForwardIcon, {}) })] })] }) }));
};
const ButtonLink = styled.a `
  align-items: center;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  text-decoration: none;
  line-height: 0;
`;
const Paper = styled.div `
  position: relative;
  display: flex;
  flex-grow: 1;
  min-height: 48px;
  max-width: 544px;
  margin: 0 auto;
  border-radius: 3px;
  background-color: ${COLORS.ACCENT};
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  &:hover {
    background-color: ${COLORS.SECONDARY_DARK};
    transition: all ease-in-out 0.3s;
    .leftLabel {
      background-color: ${COLORS.SECONDARY_DARKEST};
      transition: all ease-in-out 0.3s;
    }
  }
`;
const ButtonWrapper = styled.div `
  padding: 15px;
  background-color: ${COLORS.ACCENT_LIGHT};
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  @media ${DEVICES.SMARTPHONE} {
    margin: 0;
  }
`;
const ButtonBox = styled.div `
  display: flex;
  gap: 8px;
  align-items: center;
  flex-grow: 1;
  padding: 24px 0 24px 8px;
`;
const VodLogoBox = styled.div `
  display: flex;
  flex-shrink: 0;
`;
const VodLogoSquare = styled.img `
  object-fit: cover;
  border-radius: 8px;
`;
const MainText = styled.span `
  display: block;
  color: ${COLORS.PURE_WHITE};
  font-size: 16px;
  line-height: 1.6;
  font-weight: bold;
  word-break: break-all;
  white-space: normal;
`;
const LeftLabel = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${COLORS.ACCENT_DARK};
  width: 48px;
  border-radius: 3px 0 0 3px;
  @media (min-width: ${DEVICES.SMARTPHONE}) {
    width: 96px;
  }
`;
const LeftLabelText = styled.span `
  display: block;
  color: ${COLORS.PURE_WHITE};
  font-weight: bold;
`;
const ArrowForwardIconBox = styled.div `
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
const ArrowForwardIcon = styled.span `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  &::after {
    content: '';
    display: inline-block;
    background-image: url('${IMAGES.RIGHT_ARROW.URL}');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    background-size: contain;
    vertical-align: middle;
  }
`;
export { PrimaryPlayButton };
