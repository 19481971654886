const CATEGORIES = {
    LIVE_ACTION_MOVIE: {
        SLUG: 'live_action_movie',
        LABEL: '実写映画',
    },
    ANIME_MOVIE: {
        SLUG: 'anime_movie',
        LABEL: 'アニメ映画',
    },
    TV_DRAMA_SERIES: {
        SLUG: 'tv_drama_series',
        LABEL: 'TVドラマ',
    },
    TV_ANIME_SERIES: {
        SLUG: 'tv_anime_series',
        LABEL: 'TVアニメ',
    },
    TV_VARIETY_SERIES: {
        SLUG: 'tv_variety_series',
        LABEL: 'TVバラエティ',
    },
};
const PARENT_CATEGORIES = {
    MOVIE: {
        SLUG: 'movie',
        LABEL: '映画',
    },
    DRAMA: {
        SLUG: 'drama',
        LABEL: 'ドラマ',
    },
    ANIME: {
        SLUG: 'anime',
        LABEL: 'アニメ',
    },
    VARIETY: {
        SLUG: 'variety',
        LABEL: 'バラエティ',
    },
};
const CHILD_CATEGORIES = {
    INTERNATIONAL_MOVIE: {
        SLUG: 'international-movie',
        LABEL: '洋画',
    },
    JAPANESE_MOVIE: {
        SLUG: 'japanese-movie',
        LABEL: '邦画',
    },
    ASIAN_MOVIE: {
        SLUG: 'asian-movie',
        LABEL: '韓国/アジア映画',
    },
    ANIME_MOVIE: {
        SLUG: 'anime-movie',
        LABEL: 'アニメ映画',
    },
    INTERNATIONAL_TV_DRAMA_SERIES: {
        SLUG: 'international-tv-drama-series',
        LABEL: '海外ドラマ',
    },
    JAPANESE_TV_DRAMA_SERIES: {
        SLUG: 'japanese-tv-drama-series',
        LABEL: '国内ドラマ',
    },
    ASIAN_TV_DRAMA_SERIES: {
        SLUG: 'asian-tv-drama-series',
        LABEL: '韓国/アジアドラマ',
    },
    TV_ANIME_SERIES: {
        SLUG: 'tv-anime-series',
        LABEL: 'TVアニメ',
    },
    INTERNATIONAL_TV_VARIETY_SERIES: {
        SLUG: 'international-tv-variety-series',
        LABEL: '海外バラエティ',
    },
    JAPANESE_TV_VARIETY_SERIES: {
        SLUG: 'japanese-tv-variety-series',
        LABEL: '国内バラエティ',
    },
    ASIAN_TV_VARIETY_SERIES: {
        SLUG: 'asian-tv-variety-series',
        LABEL: '韓国/アジアバラエティ',
    },
};
const findCategory = (slug) => {
    const category = Object.values(CATEGORIES).find((CATEGORY) => {
        return CATEGORY.SLUG === slug;
    });
    if (!category) {
        throw new Error(`Category ${slug} is not found.`);
    }
    return category;
};
const findParentCategory = (slug) => {
    const parentCategory = Object.values(PARENT_CATEGORIES).find((PARENT_CATEGORY) => {
        return PARENT_CATEGORY.SLUG === slug;
    });
    if (!parentCategory) {
        throw new Error(`Category ${slug} is not found.`);
    }
    return parentCategory;
};
const findChildCategory = (slug, language) => {
    switch (slug) {
        case 'live_action_movie': {
            if (language === 'ja') {
                return CHILD_CATEGORIES.JAPANESE_MOVIE;
            }
            else if (language === 'ko' || language === 'zh') {
                return CHILD_CATEGORIES.ASIAN_MOVIE;
            }
            else {
                return CHILD_CATEGORIES.INTERNATIONAL_MOVIE;
            }
        }
        case 'anime_movie': {
            return CHILD_CATEGORIES.ANIME_MOVIE;
        }
        case 'tv_drama_series': {
            if (language === 'ja') {
                return CHILD_CATEGORIES.INTERNATIONAL_TV_DRAMA_SERIES;
            }
            else if (language === 'ko' || language === 'zh') {
                return CHILD_CATEGORIES.ASIAN_TV_DRAMA_SERIES;
            }
            else {
                return CHILD_CATEGORIES.INTERNATIONAL_TV_DRAMA_SERIES;
            }
        }
        case 'tv_anime_series': {
            return CHILD_CATEGORIES.TV_ANIME_SERIES;
        }
        case 'tv_variety_series': {
            if (language === 'ja') {
                return CHILD_CATEGORIES.JAPANESE_TV_VARIETY_SERIES;
            }
            else if (language === 'ko' || language === 'zh') {
                return CHILD_CATEGORIES.ASIAN_TV_VARIETY_SERIES;
            }
            else {
                return CHILD_CATEGORIES.INTERNATIONAL_TV_VARIETY_SERIES;
            }
        }
    }
};
const isMovieCategory = (category) => {
    return [CATEGORIES.LIVE_ACTION_MOVIE.SLUG, CATEGORIES.ANIME_MOVIE.SLUG].includes(category);
};
const isTvSeriesCategory = (category) => {
    return [CATEGORIES.TV_ANIME_SERIES.SLUG, CATEGORIES.TV_DRAMA_SERIES.SLUG, CATEGORIES.TV_VARIETY_SERIES.SLUG].includes(category);
};
export { CATEGORIES, findCategory, findParentCategory, findChildCategory, isMovieCategory, isTvSeriesCategory };
