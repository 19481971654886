import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Movie } from '~/models';
import { canWatchFreely } from '~/usecases/check';
import { getAffiiateLink } from '~/usecases/link';
import { retrieveVodById } from '~/usecases/retrieve';
import { COLORS, DEVICES, IMAGES, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
const EmphaticButton = (props) => {
    const { title, distribution, vods, widgetType, seasonNumber } = props;
    const vod = retrieveVodById(vods, distribution.vodId);
    const defaultPlan = vod.getMonthlyDefaultPlan();
    return (_jsxs("div", { children: [_jsx(MarkerWrapper, { children: canWatchFreely(distribution, defaultPlan) && (_jsxs(ButtonMarker, { children: [_jsxs(RedEmphasisText, { children: [defaultPlan.freeTrialPeriod, "\u7121\u6599"] }), "\u3067\u697D\u3057\u3080\uFF01"] })) }), _jsx(ButtonWrapper, { children: _jsxs(RedButtonLink, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                        sendLinkClick(event.currentTarget.href, {
                            'widget_type': widgetType,
                            'content_id': generateContentIdParam(title, seasonNumber),
                            'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                            'category': findParentCategory(title.getParentCategory()).LABEL,
                            'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                            'genre': generateGenreParam(title.genres),
                            'vod_name': vod.name,
                        });
                    }, children: [canWatchFreely(distribution, defaultPlan) ? `${vod.name}の無料体験で` : `今すぐ${vod.name}で`, "\u89B3\u308B"] }) })] }));
};
const MarkerWrapper = styled.div `
  text-align: center;
`;
const ButtonMarker = styled.p `
  font-size: 18px;
  font-weight: 700;
  margin: 0 auto 8px auto;
  position: relative;
  display: inline-block;

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    display: inline-block;
    width: 28px;
    height: 1px;
    background: ${COLORS.SOFT_BLACK};
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    display: inline-block;
    width: 28px;
    height: 1px;
    background: ${COLORS.SOFT_BLACK};
    -webkit-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }

  @media ${DEVICES.SMARTPHONE} {
    font-size: 16px;
    ::before {
      width: 24px;
    }
    ::after {
      width: 24px;
    }
  }
`;
const RedEmphasisText = styled.span `
  line-height: 25px;
  font-size: 24px;
  color: ${COLORS.CIATR_RED};
  @media ${DEVICES.SMARTPHONE} {
    font-size: 20px;
  }
`;
const ButtonWrapper = styled.div `
  display: flex;
  padding: 8px 0;
`;
const RedButtonLink = styled.a `
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: ${COLORS.PURE_WHITE};
  background: ${COLORS.CIATR_RED};
  box-shadow: 0 4px 0 ${COLORS.SHADOWY_RED};
  border-radius: 8px;
  width: 80%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 14px 7px;
  transition: all 0.25s ease-out;
  &:hover {
    color: ${COLORS.PURE_WHITE};
    opacity: 0.5;
  }
  &::after {
    content: '';
    display: inline-block;
    background-image: url('${IMAGES.RIGHT_ARROW.URL}');
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    margin: -3px 0 0 5px;
    background-size: contain;
    vertical-align: middle;
  }
  @media ${DEVICES.SMARTPHONE} {
    font-size: 16px;
    width: 90%;
    padding: 13px 6px;
  }
`;
export { EmphaticButton };
