import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Section } from '~/components/parts/articleSet/section';
import { COLORS } from '~/constants';
const ArticleSet = (props) => {
    const { title, seasonNumber, vods, distributions, widgetType, isPullDownOpen, setIsPullDownOpen } = props;
    return (_jsxs(Wrapper, { children: [distributions.slice(0, 3).map((distribution) => {
                return (_jsx(Section, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, vods: vods, distribution: distribution }, distribution.vodId));
            }), distributions.length > 3 && (_jsxs(AccordionWrapper, { children: [_jsxs(AccordionLabel, { children: [isPullDownOpen ? '他のサービスも見る▲' : '他のサービスも見る▼', _jsx(AccordionCheckbox, { type: "checkbox", checked: isPullDownOpen, onChange: () => {
                                    setIsPullDownOpen(!isPullDownOpen);
                                } })] }), isPullDownOpen && (_jsx(AccordionContent, { children: distributions.slice(3).map((distribution) => {
                            return (_jsx(Section, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, vods: vods, distribution: distribution }, distribution.vodId));
                        }) }))] }))] }));
};
const Wrapper = styled.div `
  margin: 0 auto;
  padding: 8px 0;
  max-width: 1080px;
`;
const AccordionWrapper = styled.div `
  margin-bottom: 24px;
`;
const AccordionCheckbox = styled.input `
  display: none;
`;
const AccordionLabel = styled.label `
  display: block;
  background: ${COLORS.LIGHTEST_GRAY};
  padding: 0.4rem 0.75rem;
  cursor: pointer;
  margin: 28px auto 0 auto;
  border-radius: 3px;
  width: 10rem;
  text-align: center;
  font-size: 0.95rem;
`;
const AccordionContent = styled.div `
  display: block;
  height: auto;
  overflow: hidden;
  padding: 0;
  opacity: 1;
  transition: 0.5s;
`;
export { ArticleSet };
