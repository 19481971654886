import { VodFeature } from '~/models/vodFeature';
import { VodPlan } from '~/models/vodPlan';
class Vod {
    constructor(props) {
        this.getSquareLogoImage = () => {
            const protocol = (process.env.APP_HOST || '').includes('localhost') ? 'http' : 'https';
            return `${protocol}://${process.env.APP_HOST}/images/logo_square/${this.slug}.webp`;
        };
        this.getRectangleLogoImage = () => {
            const protocol = (process.env.APP_HOST || '').includes('localhost') ? 'http' : 'https';
            return `${protocol}://${process.env.APP_HOST}/images/logo_rectangle/${this.slug}.webp`;
        };
        this.getMonthlyDefaultPlan = () => {
            const defaultPlan = this.vodPlans.find((vodPlan) => {
                return (vodPlan.period === 30 || vodPlan.period === 0) && vodPlan.isDefault === true;
            });
            // VOD has always not empty plans, and either of them is always checked as default.
            if (!defaultPlan) {
                throw new Error('Default monthly plan is not found.');
            }
            return defaultPlan;
        };
        this.getHasHigherPlanThan = (plan) => {
            const higherPlan = this.vodPlans.find((comparisonPlan) => {
                return plan.period === comparisonPlan.period && plan.getPrice() < comparisonPlan.getPrice();
            });
            return !!higherPlan;
        };
        this.getCommonLandingPageUrl = (params) => {
            const url = new URL(this.commonLandingPageUrl);
            Object.keys(params).forEach((key) => {
                // Use `set()` instead of `append()` in order to replace parameters.
                url.searchParams.set(key, params[key]);
            });
            return url.toString();
        };
        this.id = props.id;
        this.slug = props.slug;
        this.name = props.name;
        this.state = props.state;
        this.priority = props.priority;
        this.affiliateFee = props.affiliateFee;
        this.earnPerClick = props.earnPerClick;
        this.adjustmentScore = props.adjustmentScore || 0;
        this.canDownload = props.canDownload;
        this.topPageUrl = props.topPageUrl;
        this.titlePageUrl = props.titlePageUrl;
        this.landingPageUrl = props.landingPageUrl;
        this.commonLandingPageUrl = props.commonLandingPageUrl;
        this.sellingPoints = props.sellingPoints;
        this.strongPoints = props.strongPoints;
        this.weakPoints = props.weakPoints;
        this.ciatrArticleUrl = props.ciatrArticleUrl || null;
        this.numberOfUnlimitedDistributions = props.numberOfUnlimitedDistributions || null;
        this.numberOfRentableDistributions = props.numberOfRentableDistributions || null;
        this.vodPlans = (props.vodPlans || []).map((childProps) => {
            return new VodPlan(childProps);
        });
        this.vodFeatures = (props.vodFeatures || []).map((childProps) => {
            return new VodFeature(childProps);
        });
    }
}
export { Vod };
