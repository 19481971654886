import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { getAffiiateLink } from '~/usecases/link';
import { COLORS, IMAGES, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
const DistributionData = (props) => {
    const { tvSeries, rank, vod, distribution, widgetType, seasonNumber } = props;
    const linkUrl = getAffiiateLink(vod, distribution?.titlePageUrl, window.location);
    return (_jsx(TableData, { children: _jsxs(Link, { href: linkUrl, target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                sendLinkClick(event.currentTarget.href, {
                    'widget_type': widgetType,
                    'content_id': generateContentIdParam(tvSeries, seasonNumber),
                    'content_name': tvSeries.getName(seasonNumber),
                    'category': findParentCategory(tvSeries.getParentCategory()).LABEL,
                    'sub_category': findChildCategory(tvSeries.category, tvSeries.originalLanguage).LABEL,
                    'genre': generateGenreParam(tvSeries.genres),
                    'vod_name': vod.name,
                });
            }, children: [distribution?.isUnlimited && (_jsxs(_Fragment, { children: [_jsx(SymbolImage, { src: IMAGES.DOUBLE_CIRCLE_MARK_RED.URL, alt: IMAGES.DOUBLE_CIRCLE_MARK_RED.ALT, width: IMAGES.DOUBLE_CIRCLE_MARK_RED.WIDTH, height: IMAGES.DOUBLE_CIRCLE_MARK_RED.HEIGHT, "$small": rank !== 1 }), _jsx(UnlimitedText, { "$small": rank !== 1, children: "\u898B\u653E\u984C" })] })), !distribution?.isUnlimited && distribution?.isRentable && (_jsxs(_Fragment, { children: [_jsx(SymbolImage, { src: IMAGES.TRIANGLE_MARK_BLUE.URL, alt: IMAGES.TRIANGLE_MARK_BLUE.ALT, width: IMAGES.TRIANGLE_MARK_BLUE.WIDTH, height: IMAGES.TRIANGLE_MARK_BLUE.HEIGHT, "$small": rank !== 1 }), _jsx(Text, { "$small": rank !== 1, children: "\u30EC\u30F3\u30BF\u30EB" })] })), !distribution?.isUnlimited && !distribution?.isRentable && distribution?.isPurchasable && (_jsxs(_Fragment, { children: [_jsx(SymbolImage, { src: IMAGES.TRIANGLE_MARK_YELLOW.URL, alt: IMAGES.TRIANGLE_MARK_YELLOW.ALT, width: IMAGES.TRIANGLE_MARK_YELLOW.WIDTH, height: IMAGES.TRIANGLE_MARK_YELLOW.HEIGHT, "$small": rank !== 1 }), _jsx(Text, { "$small": rank !== 1, children: "\u8CFC\u5165" })] }))] }) }));
};
const TableData = styled.td `
  background: ${COLORS.BLACK_SQUEEZE};
  text-align: center;
  font-size: 12px;
  padding: 8px;
`;
const Link = styled.a `
  display: block;
  text-decoration: none;
  transition: all 0.25s ease-out;
  &:hover {
    opacity: 0.5;
  }
`;
const SymbolImage = styled.img `
  width: ${({ $small }) => {
    return $small ? '24px' : '32px';
}};
  height: auto;
  display: block;
  margin: ${({ $small }) => {
    return $small ? '0 auto 4px auto' : '0 auto 6px auto';
}};
`;
const UnlimitedText = styled.p `
  font-size: ${({ $small }) => {
    return $small ? '12px' : '14px';
}};
  line-height: 1;
  color: ${COLORS.FRENCH_ROSE};
  margin: 0;
`;
const Text = styled.p `
  font-size: ${({ $small }) => {
    return $small ? '12px' : '14px';
}};
  color: ${COLORS.MEDIUM_GRAY};
  line-height: 1;
  margin: 0;
`;
export { DistributionData };
