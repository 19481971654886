import { AFFILIATES } from '~/constants/affiliates';
const getAffiiateLink = (vod, titlePageUrl, location) => {
    const { id: vodId, topPageUrl } = vod;
    const { hostname: media, pathname } = location;
    const referrer = pathname.slice(1).replaceAll('/', '-');
    const affiliate = AFFILIATES.find((affiliate) => {
        return affiliate.media.some((affiliateMedeia) => {
            return affiliateMedeia === media;
        });
    });
    const getPartner = (affiliate, vodId) => {
        return affiliate.partners.find((partner) => {
            return partner.vodId === vodId;
        });
    };
    const getAffiliatedTitlePageUrl = (affiliate, vodId) => {
        const partner = getPartner(affiliate, vodId);
        if (!titlePageUrl || !(partner && Object.keys(partner?.titlePageParams).length > 0)) {
            return undefined;
        }
        const url = new URL(titlePageUrl);
        let params = partner.titlePageParams;
        const titlePageReferrerParams = Object.fromEntries(Object.entries(partner.referrerParams).map(([key, value]) => {
            return [key, value.replaceAll('{referrer}', referrer)];
        }));
        params = { ...params, ...titlePageReferrerParams };
        Object.entries(params).forEach(([key, value]) => {
            url.searchParams.append(key, value);
        });
        return url.toString();
    };
    const getAffiliatedBasePageUrl = (affiliate, vodId) => {
        const partner = getPartner(affiliate, vodId);
        if (!partner?.basePageUrl) {
            return undefined;
        }
        const url = new URL(partner.basePageUrl);
        let params = partner.basePageParams;
        // Handle DMM TV link for vodTitleId replacement
        if (vodId === 25 && titlePageUrl) {
            const match = titlePageUrl.match(/season=([a-zA-Z0-9]+)/);
            const vodTitleId = match ? match[1] : null;
            if (vodTitleId) {
                params = Object.fromEntries(Object.entries(params).map(([key, value]) => {
                    return [key, value.replace('{vodTitleId}', vodTitleId)];
                }));
            }
            else {
                return undefined;
            }
        }
        const basePageReferrerParams = Object.fromEntries(Object.entries(partner.referrerParams).map(([key, value]) => {
            return [key, value.replaceAll('{referrer}', referrer)];
        }));
        params = { ...params, ...basePageReferrerParams };
        Object.entries(params).forEach(([key, value]) => {
            url.searchParams.append(key, value);
        });
        return url.toString();
    };
    if (affiliate) {
        return (getAffiliatedTitlePageUrl(affiliate, vodId) ||
            getAffiliatedBasePageUrl(affiliate, vodId) ||
            titlePageUrl ||
            topPageUrl);
    }
    return titlePageUrl || topPageUrl;
};
export { getAffiiateLink };
