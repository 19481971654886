import { parseISO } from 'date-fns';
import { getDateAfterDays } from '~/usecases/date';
class Distribution {
    constructor(props) {
        this.id = props.id;
        this.movieId = props.movieId || null;
        this.tvSeasonId = props.tvSeasonId || null;
        this.vodId = props.vodId;
        this.vodTitleId = props.vodTitleId;
        this.originalName = props.originalName || null;
        this.japaneseName = props.japaneseName;
        this.titlePageUrl = props.titlePageUrl;
        this.landingPageUrl = props.landingPageUrl;
        this.watchingPageUrl = props.watchingPageUrl;
        this.isAffiliated = props.isAffiliated;
        this.isFree = props.isFree;
        this.isPartiallyFree = props.isPartiallyFree;
        this.isUnlimited = props.isUnlimited;
        this.isRentable = props.isRentable;
        this.isPurchasable = props.isPurchasable || null;
        this.rentalPrice = props.rentalPrice || null;
        this.purchasePrice = props.purchasePrice || null;
        this.startsAt = parseISO(props.startsAt);
        this.endsAt = props.endsAt ? parseISO(props.endsAt) : null;
        this.isOriginal = props.isOriginal || null;
        this.isSubtitled = props.isSubtitled || null;
        this.isDubbed = props.isDubbed || null;
        this.isProprietary = props.isProprietary || null;
    }
    isAvailableNow() {
        const now = new Date();
        return this.startsAt <= now && (!this.endsAt || now <= this.endsAt);
    }
    isStartingSoon() {
        const now = new Date();
        const startingSoonDays = 365 * 2;
        return now < this.startsAt && this.startsAt <= getDateAfterDays(now, startingSoonDays);
    }
    isEndingSoon() {
        const now = new Date();
        const endingSoonDays = 14;
        if (this.endsAt === null) {
            return false;
        }
        return this.startsAt <= now && now < this.endsAt && this.endsAt <= getDateAfterDays(now, endingSoonDays);
    }
}
export { Distribution };
