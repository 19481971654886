import { jsx as _jsx } from "react/jsx-runtime";
import { UNext } from './uNext';
import { Hulu } from './hulu';
import { VideoMarket } from './videoMarket';
import { AmazonPrimeVideo } from './amazonPrimeVideo';
import { DTv } from './dTv';
import { Fod } from './fod';
import { TsutayaDiscas } from './tsutayaDiscas';
import { TsutayaTv } from './tsutayaTv';
import { DisneyPlus } from './disneyPlus';
import { Paravi } from './paravi';
import { Netflix } from './netflix';
import { TVer } from './tver';
import { Abema } from './abema';
import { DAnimeStore } from './dAnimeStore';
import { Dazn } from './dazn';
import { Telasa } from './telasa';
import { CrankInVideo } from './crankInVideo';
import { Gyao } from './gyao';
import { MusicJp } from './musicJp';
import { WowowOnDemand } from './wowowOnDemand';
import { Watcha } from './watcha';
import { GyaoStore } from './gyaoStore';
const ServiceIntroduceText = (props) => {
    const { vod } = props;
    const serviceIntroduceText = {
        1: _jsx(UNext, {}),
        2: _jsx(Hulu, {}),
        3: _jsx(VideoMarket, {}),
        4: _jsx(AmazonPrimeVideo, {}),
        5: _jsx(DTv, {}),
        6: _jsx(Fod, {}),
        7: _jsx(TsutayaDiscas, {}),
        8: _jsx(TsutayaTv, {}),
        9: _jsx(DisneyPlus, {}),
        10: _jsx(Paravi, {}),
        11: _jsx(Netflix, {}),
        12: _jsx(TVer, {}),
        13: _jsx(Abema, {}),
        14: _jsx(DAnimeStore, {}),
        15: _jsx(Dazn, {}),
        16: _jsx(Telasa, {}),
        17: _jsx(CrankInVideo, {}),
        18: _jsx(Gyao, {}),
        20: _jsx(MusicJp, {}),
        21: _jsx(WowowOnDemand, {}),
        22: _jsx(Watcha, {}),
        23: _jsx(GyaoStore, {}),
    };
    return serviceIntroduceText[vod.id] || null;
};
export { ServiceIntroduceText };
