import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { COLORS } from '~/constants';
const MessageForNoDistribution = (props) => {
    const { titleName } = props;
    return (_jsxs(NormalText, { children: [_jsx(MarkerText, { children: `${titleName}はどこのサービスでも配信されていません。` }), "\u914D\u4FE1\u304C\u59CB\u307E\u308A\u6B21\u7B2C\u3001\u3053\u306E\u8A18\u4E8B\u3067\u30B5\u30FC\u30D3\u30B9\u3092\u7D39\u4ECB\u3057\u307E\u3059\u3002"] }));
};
const NormalText = styled.p `
  font-size: 15.4px;
`;
const MarkerText = styled.span `
  background: linear-gradient(transparent 60%, ${COLORS.ONAHAU_BLUE} 0%);
  line-height: 25px;
  font-size: 15.4px;
  font-weight: bolder;
`;
export { MessageForNoDistribution };
