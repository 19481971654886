const PROTOCOL = (process.env.APP_HOST || '').includes('localhost') ? 'http' : 'https';
const IMAGES = {
    CROWN_BLONDS: {
        ALT: '王冠 ブロンズ',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/material/blonds.webp`,
        WIDTH: 72,
        HEIGHT: 60,
    },
    CROWN_GOLD: {
        ALT: '王冠 ゴールド',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/material/gold.webp`,
        WIDTH: 72,
        HEIGHT: 60,
    },
    CROWN_SILVER: {
        ALT: '王冠 シルバー',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/material/silver.webp`,
        WIDTH: 72,
        HEIGHT: 60,
    },
    CRYING_BOY: {
        ALT: '泣いている男の子',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/material/crying_boy.webp`,
        WIDTH: 200,
        HEIGHT: 200,
    },
    RIGHT_ARROW: {
        ALT: '右矢印',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/icon/right_arrow.svg`,
        WIDTH: 200,
        HEIGHT: 200,
    },
    CROWN_LABEL: {
        ALT: '王冠ラベル',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/icon/crown_label.svg`,
        WIDTH: 100,
        HEIGHT: 100,
    },
    DOUBLE_CIRCLE_MARK: {
        ALT: '二重マル',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/icon/double_circle_mark.svg`,
        WIDTH: 96,
        HEIGHT: 96,
    },
    DOUBLE_CIRCLE_MARK_RED: {
        ALT: '二重マル 赤',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/icon/double_circle_mark_red.svg`,
        WIDTH: 40,
        HEIGHT: 40,
    },
    CIRCLE_MARK: {
        ALT: 'マル',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/icon/circle_mark.svg`,
        WIDTH: 96,
        HEIGHT: 96,
    },
    TRIANGLE_MARK: {
        ALT: '三角',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/icon/triangle_mark.svg`,
        WIDTH: 96,
        HEIGHT: 96,
    },
    TRIANGLE_MARK_BLUE: {
        ALT: '三角 青',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/icon/triangle_mark_blue.svg`,
        WIDTH: 40,
        HEIGHT: 40,
    },
    TRIANGLE_MARK_YELLOW: {
        ALT: '三角 黄',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/icon/triangle_mark_yellow.svg`,
        WIDTH: 40,
        HEIGHT: 40,
    },
    CROSS_MARK: {
        ALT: 'バツ',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/icon/cross_mark.svg`,
        WIDTH: 96,
        HEIGHT: 96,
    },
    ONESCREEN_LOGO: {
        ALT: '1Screen',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/logo_rectangle/onescreen.svg`,
        WIDTH: 180,
        HEIGHT: 33,
    },
    RECOMMEND_CROWN: {
        ALT: 'おすすめサービス',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/material/recommend_crown.webp`,
        WIDTH: 96,
        HEIGHT: 60,
    },
    NO_IMAGE_SQUARE: {
        ALT: '',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/logo_square/no_image.webp`,
        WIDTH: 120,
        HEIGHT: 120,
    },
    NO_IMAGE_RECTANGLE: {
        ALT: '',
        URL: `${PROTOCOL}://${process.env.APP_HOST}/images/logo_rectangle/no_image.webp`,
        WIDTH: 300,
        HEIGHT: 200,
    },
};
export { IMAGES };
