import { Movie, TvSeries, Vod } from '~/models';
import { request } from './client';
const findMovie = async (movieId) => {
    return request(`/movies/${movieId}?with=posters,distributions,genres`)
        .then((result) => {
        return new Movie(result.data);
    })
        .catch(() => {
        throw new Error(`It failed to get a movie (ID: ${movieId}).`);
    });
};
const findTvSeries = async (tvSeriesId) => {
    return request(`/tv-series/${tvSeriesId}?with=posters,tvSeasons,tvSeasons.distributions,tvSeasons.posters,genres`)
        .then((result) => {
        return new TvSeries(result.data);
    })
        .catch(() => {
        throw new Error(`It failed to get a TV series (ID: ${tvSeriesId}).`);
    });
};
const findVod = async (vodId) => {
    return request(`/vods/${vodId}?with=vodPlans`)
        .then((result) => {
        return new Vod(result.data);
    })
        .catch(() => {
        throw new Error(`It failed to get a VOD (ID: ${vodId}).`);
    });
};
const searchMovieList = async (params) => {
    if (params.cinemacafeMovieId) {
        return request(`/movies?cinemacafeMovieId=${params.cinemacafeMovieId}&with=posters,distributions`)
            .then((result) => {
            return result.data.map((movie) => {
                return new Movie(movie);
            });
        })
            .catch(() => {
            throw new Error(`It failed to get movies.`);
        });
    }
    if (params.natalieMovieId) {
        return request(`/movies?natalieMovieId=${params.natalieMovieId}&with=posters,distributions`)
            .then((result) => {
            return result.data.map((movie) => {
                return new Movie(movie);
            });
        })
            .catch(() => {
            throw new Error(`It failed to get movies.`);
        });
    }
    if (params.category) {
        return request(`/movies?category=${params.category}&with=posters,distributions&page=1&limit=10`)
            .then((result) => {
            return result.data.map((movie) => {
                return new Movie(movie);
            });
        })
            .catch(() => {
            throw new Error(`It failed to get movies.`);
        });
    }
    return [];
};
const searchTvSeriesList = async (params) => {
    if (params.natalieDramaId) {
        return request(`/tv-series?natalieDramaId=${params.natalieDramaId}&with=posters,tvSeasons,tvSeasons.posters,tvSeasons.distributions`)
            .then((result) => {
            return result.data.map((tvSeries) => {
                return new TvSeries(tvSeries);
            });
        })
            .catch(() => {
            throw new Error(`It failed to get TV series.`);
        });
    }
    if (params.natalieAnimeId) {
        return request(`/tv-series?natalieAnimeId=${params.natalieAnimeId}&with=posters,tvSeasons,tvSeasons.posters,tvSeasons.distributions`)
            .then((result) => {
            return result.data.map((tvSeries) => {
                return new TvSeries(tvSeries);
            });
        })
            .catch(() => {
            throw new Error(`It failed to get TV series.`);
        });
    }
    if (params.category) {
        return request(`/tv-series?category=${params.category}&with=posters,tvSeasons,tvSeasons.posters,tvSeasons.distributions&page=1&limit=10`)
            .then((result) => {
            return result.data.map((tvSeries) => {
                return new TvSeries(tvSeries);
            });
        })
            .catch(() => {
            throw new Error(`It failed to get TV series.`);
        });
    }
    return [];
};
const getAllVodList = async () => {
    return request('/vods?with=vodPlans')
        .then((result) => {
        return result.data.map((vod) => {
            return new Vod(vod);
        });
    })
        .catch((error) => {
        window.console.error(error);
        return [];
    });
};
export { findMovie, findTvSeries, findVod, searchMovieList, searchTvSeriesList, getAllVodList };
