import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { Small } from '~/components/parts/serviceIntroduceText/atoms';
import { COLORS, DEVICES, IMAGES } from '~/constants';
import { sendLinkClick } from '~/components/functions/analytics';
import { getAffiiateLink } from '~/usecases/link';
const VodSummary = (props) => {
    const { vod, hasAdditionalVodCaveats, widgetType } = props;
    const defaultPlan = vod.getMonthlyDefaultPlan();
    return (_jsxs(Wrapper, { children: [_jsx(VodLogo, { children: _jsx(VodLogoImage, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 360, height: 240, onError: (event) => {
                        event.currentTarget.style.display = 'none';
                    } }) }), _jsx(BasicInformation, { children: _jsxs(BasicInformationTable, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(TableHead, { children: "\u6708\u984D\u6599\u91D1" }), _jsx(TableHead, { children: "\u7121\u6599\u671F\u9593" }), _jsx(TableHead, { children: "\u898B\u653E\u984C\u4F5C\u54C1\u6570" })] }) }), _jsx("tbody", { children: _jsxs("tr", { children: [_jsxs(TableData, { children: [defaultPlan.getPrice() > 0 ? `${defaultPlan.getPrice().toLocaleString('en-US')}円` : '無料', vod.getHasHigherPlanThan(defaultPlan) ? '〜' : '', _jsx(SmallText, { children: defaultPlan.getPrice() > 0 ? '(税込)' : '' })] }), _jsxs(TableData, { "$emphasis": true, children: [defaultPlan.isFree() ? '' : defaultPlan.freeTrialPeriod || 'なし', _jsx("br", {}), vod.id === 4 && _jsx(SmallText, { children: "(\u5B66\u751F\u306F6\u30F6\u6708)" })] }), _jsx(TableData, { children: vod.numberOfUnlimitedDistributions || '-' })] }) })] }) }), _jsxs(Feature, { children: [_jsx(FeatureHead, { children: "\u30E9\u30A4\u30F3\u30CA\u30C3\u30D7\u306E\u3053\u3053\u304C\u9B45\u529B\uFF01" }), _jsx(FeatureList, { children: vod.sellingPoints.map((sellingPoint) => {
                            return _jsx(FeatureItem, { children: sellingPoint }, sellingPoint);
                        }) })] }), _jsxs(Description, { children: [_jsxs(RecommendPoint, { children: [_jsx(Label, { "$bold": true, children: "\u304A\u3059\u3059\u3081\u30DD\u30A4\u30F3\u30C8" }), _jsx(DescriptionList, { children: vod.strongPoints.map((strongPoint) => {
                                    return _jsx(RecommendPointItem, { children: strongPoint }, strongPoint);
                                }) })] }), _jsxs(Alert, { children: [_jsx(Label, { children: "\u6CE8\u610F\u70B9" }), _jsx(DescriptionList, { children: vod.weakPoints.map((weakPoint) => {
                                    return _jsx(AlertItem, { children: weakPoint }, weakPoint);
                                }) })] })] }), _jsxs(VodLink, { children: [_jsxs(VodLinkItem, { href: getAffiiateLink(vod, undefined, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                            sendLinkClick(event.currentTarget.href, {
                                'widget_type': widgetType,
                                'vod_name': vod.name,
                            });
                        }, children: [_jsx(ServiceLogo, { src: vod.getSquareLogoImage(), alt: vod.name, width: 60, height: 60, onError: (event) => {
                                    event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                } }), _jsxs(ServiceBody, { children: [_jsx("strong", { children: "\u516C\u5F0F\u30B5\u30A4\u30C8\u3092\u30C1\u30A7\u30C3\u30AF\u3059\u308B" }), _jsx("br", {}), _jsx(VodName, { children: vod.name })] }), _jsx(Chevron, {})] }), 
                    // Displayed only if some widgets and "d_anime_store".
                    hasAdditionalVodCaveats && vod.id === 14 && (_jsx(Caveats, { children: _jsxs(Small, { children: ["\u203B\u6708\u984D\u6599\u91D1\u306B\u3064\u3044\u3066", _jsx("br", {}), "\u30FB\u5951\u7D04\u65E5\u30FB\u89E3\u7D04\u65E5\u306B\u95A2\u308F\u3089\u305A\u3001\u6BCE\u67081\u65E5\uFF5E\u672B\u65E5\u307E\u3067\u306E1\u304B\u6708\u5206\u306E\u6599\u91D1\u304C\u767A\u751F\u3057\u307E\u3059\u3002", _jsx("br", {}), "\u30FB\u5225\u9014\u901A\u4FE1\u6599\u3001\u305D\u306E\u4ED6\u30EC\u30F3\u30BF\u30EB\u6599\u91D1\u7B49\u30B5\u30FC\u30D3\u30B9\u306B\u3088\u3063\u3066\u306F\u5225\u6599\u91D1\u304C\u767A\u751F\u3057\u307E\u3059\u3002", _jsx("br", {}), "\u203B\u7121\u6599\u671F\u9593\u306B\u3064\u3044\u3066", _jsx("br", {}), "\u30FB\u521D\u56DE31\u65E5\u9593\u7121\u6599\u300231\u65E5\u7D4C\u904E\u5F8C\u306F\u81EA\u52D5\u7D99\u7D9A\u3068\u306A\u308A\u3001\u305D\u306E\u6708\u304B\u3089\u6708\u984D\u6599\u91D1\u5168\u984D\u304C\u304B\u304B\u308A\u307E\u3059\u3002"] }) }))] })] }));
};
const Wrapper = styled.div `
  max-width: 1080px;
`;
const VodLogo = styled.div `
  display: flex;
`;
const VodLogoImage = styled.img `
  margin: 0 auto;
  background-color: ${COLORS.PURE_WHITE};

  @media ${DEVICES.SMARTPHONE} {
    width: 100%;
  }
`;
const BasicInformation = styled.div `
  margin-bottom: 16px;
`;
const BasicInformationTable = styled.table `
  width: 100%;
  table-layout: fixed;
  border-spacing: 2px;
  color: ${COLORS.CASAL};
  line-height: 1;
`;
const TableHead = styled.th `
  background: ${COLORS.CHARLOTTE};
  font-size: 14px;
  padding: 8px;

  @media ${DEVICES.SMARTPHONE} {
    font-size: 12px;
  }
`;
const TableData = styled.td `
  background: ${COLORS.BLACK_SQUEEZE};
  text-align: center;
  font-size: 18px;
  padding: 12px;
  font-weight: bold;
  color: ${(props) => {
    return props.$emphasis ? COLORS.CIATR_RED : COLORS.CASAL;
}};

  @media ${DEVICES.SMARTPHONE} {
    font-size: 16px;
    padding: 8 px;
  }
`;
const SmallText = styled.span `
  font-size: 10px;
`;
const Feature = styled.div `
  margin-bottom: 16px;
  background: ${COLORS.BLACK_SQUEEZE};
`;
const FeatureHead = styled.h4 `
  color: ${COLORS.LIGHT_TURQUOISE};
  font-weight: bold;
  font-size: 14px;
  padding: 16px 8px 12px;
  margin: 0 8px 2px;
  border-bottom: 1px solid ${COLORS.CHARLOTTE};

  @media ${DEVICES.SMARTPHONE} {
    font-size: 12px;
  }
`;
const FeatureList = styled.ul `
  color: ${COLORS.CASAL};
  font-weight: bold;
  font-size: 15.4px;
  margin: 0;
  padding: 8px;
  counter-reset: list;
  list-style-type: none;
`;
const FeatureItem = styled.li `
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 32px;
  margin: 12px 0;

  &::before {
    counter-increment: list;
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 5.5px);
    left: 8px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    border: 2px solid ${COLORS.LIGHT_TURQUOISE};
  }
`;
const Description = styled.div `
  padding: 16px 8px;
  border: 3px solid ${COLORS.LIGHT_TURQUOISE};
  border-radius: 5px;
  margin-bottom: 16px;
`;
const RecommendPoint = styled.div `
  border-bottom: 1px solid ${COLORS.LIGHT_TURQUOISE};
  padding-bottom: 8px;
`;
const Label = styled.p `
  margin: 0;
  font-weight: ${({ $bold }) => {
    return $bold ? 'bold' : 'normal';
}};
`;
const DescriptionList = styled.ul `
  counter-reset: list;
  list-style-type: none;
  padding-left: 8px;
  margin: 0 0 8px 4px;
`;
const listItem = css `
  position: relative;
  padding-left: 16px;
  margin: 4px 0;
  font-size: 14px;
  line-height: 1.5;

  &::before {
    counter-increment: list;
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 3.5px);
    left: 0;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: ${COLORS.LIGHT_TURQUOISE};
  }
`;
const RecommendPointItem = styled.li `
  ${listItem}
  font-size: 15.4px;
  font-weight: bold;
`;
const Alert = styled.div `
  margin-top: 12px;
`;
const AlertItem = styled.li `
  ${listItem}

  &::before {
    background: ${COLORS.AMARANTH};
  }
`;
const VodLink = styled.div `
  margin-bottom: 16px;
`;
const VodLinkItem = styled.a `
  display: grid;
  grid-template-columns: 60px 1fr 40px;
  text-decoration: none;
  color: ${COLORS.SOFT_BLACK};
  padding: 4px;
  background-color: ${COLORS.ULTRA_LIGHT_GRAY};
  border-radius: 8px;
  transition: all 0.25s ease-out;
  &:hover {
    opacity: 0.5;
  }
`;
const ServiceLogo = styled.img `
  width: 60px;
  height: 60px;
  object-fit: cover;
  border: 1px solid ${COLORS.MERCURY};
  border-radius: 8px;
`;
const ServiceBody = styled.div `
  margin: auto 16px;
`;
const VodName = styled.span `
  font-size: 12px;

  @media ${DEVICES.SMARTPHONE} {
    font-size: 10px;
  }
`;
const Chevron = styled.div `
  border: 2px solid ${COLORS.MEDIUM_GRAY};
  border-left: 0;
  border-top: 0;
  display: block;
  height: 8px;
  margin: auto;
  transform: rotate(-45deg);
  width: 8px;
`;
const Caveats = styled.div `
  margin-top: 8px;
`;
export { VodSummary };
