// Urls and query params refer to https://www.notion.so/URL-12d688b75d7e8198a90aeb225b74f3ca .
// To avoid double encoding, "AFFILIATES" lists them decoded.
const AFFILIATES = [
    {
        media: ['ciatr.jp', 'matome-view.viviane-dev.com', 'matome-admin.ciatr.jp', 'matome-admin.viviane-dev.com'],
        partners: [
            {
                vodId: 1, // U-NEXT
                titlePageParams: {
                    cid: 'D33593',
                    adid: 'XXX',
                    utm_source: 'ciatr',
                    utm_medium: 'a_n',
                    utm_campaign: 'a_n',
                },
                referrerParams: {
                    'rid': 'VI_{referrer}',
                },
                basePageUrl: 'https://www.video.unext.jp/lp/ppd_contents_h/',
                basePageParams: {
                    cid: 'D33593',
                    adid: 'XXX',
                    utm_source: 'ciatr',
                    utm_medium: 'a_n',
                    utm_campaign: 'a_n',
                },
            },
            {
                vodId: 2, // Hulu
                titlePageParams: {
                    waad: 'BRkrV1Sh',
                    cmp: '10348',
                    utm_source: 'ciatr',
                    utm_content: 'non',
                    utm_term: 'affiliate',
                    utm_medium: 'cpa',
                    utm_campaign: 'JP_DM_Affiliate_Ciatr',
                },
                referrerParams: {},
                basePageUrl: 'https://www.hulu.jp/static/3_7/',
                basePageParams: {
                    waad: 'BRkrV1Sh',
                    cmp: '10348',
                    utm_source: 'ciatr',
                    utm_content: 'non',
                    utm_term: 'affiliate',
                    utm_medium: 'cpa',
                    utm_campaign: 'JP_DM_Affiliate_Ciatr',
                },
            },
            {
                vodId: 4, // Amazon プライム・ビデオ
                titlePageParams: {
                    tag: 'vod-affilliate-22',
                },
                referrerParams: {},
                basePageUrl: 'https://www.amazon.co.jp/gp/video/storefront',
                basePageParams: {
                    tag: 'vod-affilliate-22',
                },
            },
            {
                vodId: 6, // FOD
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://c2.cir.io/QNcdOq',
                basePageParams: {},
            },
            {
                vodId: 7, // TSUTAYA DISCAS
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://px.a8.net/svt/ejp',
                basePageParams: {
                    a8mat: '3HMY9R 461982 47OU BWVTE',
                },
            },
            {
                vodId: 9, // Disney+
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://h.accesstrade.net/sp/cc',
                basePageParams: {
                    rk: '0100p2ln00o3y6',
                },
            },
            {
                vodId: 13, // ABEMAプレミアム
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'http://cm-12998.csolution.jp/c.neo/af/a12.3/-/a4d.html',
                basePageParams: {},
            },
            {
                vodId: 14, // dアニメストア
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000002559/127/24',
                basePageParams: {},
            },
            {
                vodId: 15, // DAZN
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://px.a8.net/svt/ejp',
                basePageParams: {
                    a8mat: '3BDZ66 691VG2 491Q 5YJRM',
                },
            },
            {
                vodId: 20, // music.jp
                titlePageParams: {},
                basePageUrl: 'https://nsysapl.mopita.com/naf/naf_gdc.aspx',
                referrerParams: {
                    'mdicd': '{referrer}',
                },
                basePageParams: {
                    aspcd: 'fahrk',
                    uprmdicd: '',
                    prgid: '203',
                },
            },
            {
                vodId: 21, // WOWOWオンデマンド
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://px.a8.net/svt/ejp',
                basePageParams: {
                    a8mat: '3TJHEC 30YCHE 5DFW 5YJRM',
                },
            },
            {
                vodId: 24, // Lemino
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000000236/127/2',
                basePageParams: {},
            },
            {
                vodId: 25, // DMM TV
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://rcv.ixd.dmm.com/api/surl',
                basePageParams: {
                    lurl: 'https://www.dmm.com/pr/premium/-/dmmtv/season/{vodTitleId}',
                    urid: 'mekUJtpc',
                },
            },
            {
                vodId: 27, // Rakuten TV
                titlePageParams: {
                    scid: 'af_exl_ciatr',
                    utm_source: 'ciatr',
                    utm_medium: 'referral',
                    utm_campaign: 'promotion_content',
                    argument: 'OKzUTvnU',
                    dmai: 'a646dab4775aee',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 29, // Apple TV+
                titlePageParams: {},
                referrerParams: {
                    'ct': '{referrer}',
                },
                basePageUrl: 'https://tv.apple.com/jp/channel/tvs.sbd.4000',
                basePageParams: {
                    itsct: 'AppleTV',
                    itscg: '30200',
                    at: '1000lQWp',
                },
            },
            {
                vodId: 30, // TSUBURAYA IMAGINATION
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://prf.hn/click/camref:1011lkZh8/pubref:ciatr',
                basePageParams: {},
            },
        ],
    },
    {
        media: ['www.pintscope.com'],
        partners: [
            {
                vodId: 1, // U-NEXT
                titlePageParams: {
                    cid: 'D34219',
                    adid: 'XXX',
                    utm_source: '1screen_pintscope',
                    utm_medium: 'a_n',
                    utm_campaign: 'a_n',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 2, // Hulu
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://www.hulu.jp',
                basePageParams: {
                    cmp: '10777',
                    utm_medium: 'cpa',
                    utm_source: '1Screen_pintscope',
                    utm_content: 'non',
                    utm_term: 'affiliate',
                    utm_campaign: 'JP_DM_Affiliate_1Screen',
                    waad: 'SzOgQ53m',
                },
            },
            {
                vodId: 4, // Amazon プライム・ビデオ
                titlePageParams: {
                    tag: '1screen-pintscope-22',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 6, // FOD
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://c2.cir.io/4FwZDq',
                basePageParams: {},
            },
            {
                vodId: 7, // TSUTAYA DISCAS
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://px.a8.net/svt/ejp',
                basePageParams: {
                    a8mat: '3HMY9R 46MQDE 47OU BWVTE',
                },
            },
            {
                vodId: 9, // Disney+
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://h.accesstrade.net/sp/cc',
                basePageParams: {
                    rk: '0100p2ln00o46c',
                },
            },
            {
                vodId: 13, // ABEMAプレミアム
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://cm-12998.csolution.jp/c.neo/af/a12.d/-/aix.html',
                basePageParams: {},
            },
            {
                vodId: 14, // dアニメストア
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000002559/465/24',
                basePageParams: {},
            },
            {
                vodId: 24, // Lemino
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000000236/465/2',
                basePageParams: {},
            },
        ],
    },
    {
        media: ['www.cinemacafe.net', 's.cinemacafe.net'],
        partners: [
            {
                vodId: 1, // U-NEXT
                titlePageParams: {
                    cid: 'D34218',
                    adid: 'XXX',
                    utm_source: '1screen_cinemacafe',
                    utm_medium: 'a_n',
                    utm_campaign: 'a_n',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 2, // Hulu
                titlePageParams: {
                    utm_source: '1Screen_cinemacafe',
                    utm_campaign: 'JP_DM_Affiliate_1Screen',
                    utm_content: 'non',
                    utm_term: 'affiliate',
                    utm_medium: 'cpa',
                    cmp: '10777',
                    waad: 'JATiHhWr',
                },
                referrerParams: {},
                basePageParams: {},
                basePageUrl: null,
            },
            {
                vodId: 4, // Amazon プライム・ビデオ
                titlePageParams: {
                    tag: '1screen-cinemacafe-22',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 6, // FOD
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://c2.cir.io/libGUv',
                basePageParams: {},
            },
            {
                vodId: 7, // TSUTAYA DISCAS
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://px.a8.net/svt/ejp',
                basePageParams: {
                    a8mat: '3HMY9R 46MPLM 47OU BWVTE',
                },
            },
            {
                vodId: 9, // Disney+
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://h.accesstrade.net/sp/cc',
                basePageParams: {
                    rk: '0100p2ln00o46b',
                },
            },
            {
                vodId: 13, // ABEMAプレミアム
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://cm-12998.csolution.jp/c.neo/af/a12.c/-/a0q.html',
                basePageParams: {},
            },
            {
                vodId: 14, // dアニメストア
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000002559/464/24',
                basePageParams: {},
            },
            {
                vodId: 24, // Lemino
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000000236/464/2',
                basePageParams: {},
            },
        ],
    },
    {
        media: ['otomoshuma.com'],
        partners: [
            {
                vodId: 1, // U-NEXT
                titlePageParams: {
                    cid: 'D34317',
                    adid: 'XXX',
                    utm_source: '1screen_otomoshuma',
                    utm_medium: 'a_n',
                    utm_campaign: 'a_n',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 2, // Hulu
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://www.hulu.jp',
                basePageParams: {
                    cmp: '10777',
                    utm_medium: 'cpa',
                    utm_source: '1Screen_otomoshuma',
                    utm_content: 'non',
                    utm_term: 'affiliate',
                    utm_campaign: 'JP_DM_Affiliate_1Screen',
                    waad: 'vCYkuHZg',
                },
            },
            {
                vodId: 4, // Amazon プライム・ビデオ
                titlePageParams: {
                    tag: '1screen-otomoshuma-22',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 9, // Disney+
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://h.accesstrade.net/sp/cc',
                basePageParams: {
                    rk: '0100p2ln00o46e',
                },
            },
            {
                vodId: 7, // TSUTAYA DISCAS
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://px.a8.net/svt/ejp',
                basePageParams: {
                    a8mat: '3HMY9R 46MU8A 47OU BWVTE',
                },
            },
            {
                vodId: 14, // dアニメストア
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000002559/468/24',
                basePageParams: {},
            },
            {
                vodId: 24, // Lemino
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000000236/468/2',
                basePageParams: {},
            },
            {
                vodId: 27, // Rakuten TV
                titlePageParams: {
                    scid: 'af_exl_ootomos',
                    utm_source: 'ootomos',
                    utm_medium: 'referral',
                    utm_campaign: 'promotion_content',
                    argument: 'OKzUTvnU',
                    dmai: 'a65d5e23c16bb8',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
        ],
    },
    {
        media: ['natalie.mu'],
        partners: [
            {
                vodId: 1, // U-NEXT
                titlePageParams: {
                    cid: 'D34423',
                    adid: 'XXX',
                    utm_source: '1screen_natalie',
                    utm_medium: 'a_n',
                    utm_campaign: 'a_n',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 2, // Hulu
                titlePageParams: {
                    cmp: '10777',
                    utm_medium: 'cpa',
                    utm_source: '1Screen_natalie',
                    utm_content: 'non',
                    utm_term: 'affiliate',
                    utm_campaign: 'JP_DM_Affiliate_1Screen',
                    waad: '1NWTDvVJ',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 4, // Amazon プライム・ビデオ
                titlePageParams: {
                    tag: '1screen-natalie-22',
                },
                referrerParams: {},
                basePageUrl: null,
                basePageParams: {},
            },
            {
                vodId: 7, // TSUTAYA DISCAS
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://px.a8.net/svt/ejp',
                basePageParams: {
                    a8mat: '3HMY9R 46MV02 47OU BWVTE',
                },
            },
            {
                vodId: 9, // Disney+
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://h.accesstrade.net/sp/cc',
                basePageParams: {
                    rk: '0100p2l400o74y',
                },
            },
            {
                vodId: 14, // dアニメストア
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000002559/2774/24',
                basePageParams: {},
            },
            {
                vodId: 24, // Lemino
                titlePageParams: {},
                referrerParams: {},
                basePageUrl: 'https://tr.affiliate-sp.docomo.ne.jp/cl/d0000000236/2774/2',
                basePageParams: {},
            },
        ],
    },
];
export { AFFILIATES };
