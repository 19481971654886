import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Movie, TvSeries } from '~/models';
import { retrieveVodById } from '~/usecases/retrieve';
import { useOgp } from '~/components/hooks/useOgp';
import { EmphaticButton } from '~/components/parts/emphaticButton';
import { ServiceIntroduceText } from '~/components/parts/serviceIntroduceText';
import { VodSummary } from '~/components/parts/vodSummary';
import { COLORS, DEVICES, IMAGES, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
const Section = (props) => {
    const { title, seasonNumber, vods, distribution, widgetType } = props;
    const vod = retrieveVodById(vods, distribution.vodId);
    const defaultPlan = vod.getMonthlyDefaultPlan();
    const titleName = title instanceof TvSeries ? title.getNameWithBrackets(seasonNumber) : title.getNameWithBrackets();
    const ogp = useOgp(vod.ciatrArticleUrl || '');
    return (_jsxs(_Fragment, { children: [_jsx(Head, { children: (() => {
                    if (distribution.isFree) {
                        return `${vod.name}では無料で配信中！`;
                    }
                    if (distribution.isUnlimited) {
                        if (defaultPlan.hasFreeTrial()) {
                            return `${vod.name}では見放題で配信中！【${defaultPlan.freeTrialPeriod}無料】`;
                        }
                        return `${vod.name}では見放題で配信中`;
                    }
                    if (distribution.isRentable) {
                        if (distribution.vodId === 6) {
                            return 'FODでレンタルで配信中';
                        }
                        if (distribution.vodId === 7) {
                            return 'TSUTAYA DISCASで宅配レンタル可能！【旧作無料】';
                        }
                        if (defaultPlan.initialPoint && defaultPlan.initialPoint > 0) {
                            return `${vod.name}で配信中！【初回登録ポイントで無料視聴】`;
                        }
                        return `${vod.name}ではレンタルで配信中`;
                    }
                    return `${vod.name}で配信中`;
                })() }), _jsx(VodSummary, { widgetType: widgetType, vod: vod, hasAdditionalVodCaveats: false }), _jsx(Description, { children: (() => {
                    if (distribution.isUnlimited) {
                        if (defaultPlan.hasFreeTrial()) {
                            return (_jsxs(_Fragment, { children: ["\u521D\u56DE\u767B\u9332\u306E\u5834\u5408\u306F\u30C8\u30E9\u30A4\u30A2\u30EB\u671F\u9593\u304C\u8A2D\u3051\u3089\u308C\u3066\u3044\u308B\u305F\u3081\u3001", `登録後${defaultPlan.freeTrialPeriod}は${vod.id === 1 ? '無料トライアル' : '無料'}で${titleName}の動画を視聴することができます。`, `トライアル期間には本作だけでなく、他の${vod.id === 1 ? '対象作品も見放題' : '見放題対象作品も無料'}で楽しめます。`] }));
                        }
                        return null;
                    }
                    if (distribution.isRentable) {
                        if (distribution.vodId === 6) {
                            return `FODでは${titleName}をレンタル(有料)で配信中です。`;
                        }
                        if (distribution.vodId === 7) {
                            return (_jsxs(_Fragment, { children: [`${titleName}はTSUTAYA DISCASで宅配レンタルが可能です。`, "\u521D\u56DE\u767B\u9332\u306E\u5834\u5408\u3001\u767B\u9332\u5F8C30\u65E5\u9593\u306F\u65E7\u4F5C\u304C\u7121\u6599\u3067\u501F\u308A\u653E\u984C\uFF01", _jsx("br", {}), _jsx("br", {}), "TSUTAYA DISCAS\u306F\u3001TSUTAYA\u304C\u904B\u55B6\u3059\u308BDVD\u30FBCD\u306E\u5B85\u914D\u30EC\u30F3\u30BF\u30EB\u30B5\u30FC\u30D3\u30B9\u3002", `「${defaultPlan.name}」というプランに加入すると、`, `月額${defaultPlan.getPrice().toLocaleString('en-US')}円(税込)で`, "\u65B0\u4F5C\u30FB\u65E7\u4F5C\u554F\u308F\u305ADVD\u3092\u6708\u306B8\u679A\u307E\u3067\u30EC\u30F3\u30BF\u30EB\u53EF\u80FD\u306B\u306A\u308A\u307E\u3059\u3002"] }));
                        }
                        if (defaultPlan.initialPoint && defaultPlan.initialPoint > 0) {
                            return (_jsxs(_Fragment, { children: [`${vod.name}では${titleName}が有料配信中です。`, `レンタル対象作品ですが、トライアル期間でもらえる${defaultPlan.initialPoint}ポイントを使用すれば実質無料で視聴可能！`, _jsx("br", {}), _jsx("br", {}), "\u203B\u30A8\u30D4\u30BD\u30FC\u30C9\u304C\u8907\u6570\u3042\u308B\u5834\u5408\u3084\u4F5C\u54C1\u306B\u3088\u3063\u3066\u306F\u3001\u7121\u6599\u30C8\u30E9\u30A4\u30A2\u30EB\u671F\u9593\u3067\u3082\u3089\u3048\u308B\u30DD\u30A4\u30F3\u30C8\u3067\u306F\u8DB3\u308A\u306A\u3044\u53EF\u80FD\u6027\u304C\u3042\u308B\u306E\u3067\u6CE8\u610F\u304C\u5FC5\u8981\u3067\u3059\uFF01"] }));
                        }
                        return `${vod.name}では${titleName}をレンタル(有料)で配信中です。`;
                    }
                    return null;
                })() }), _jsx(ServiceIntroduceText, { vod: vod }), _jsx(EmphaticButton, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, distribution: distribution, vods: vods }), vod.ciatrArticleUrl && (_jsxs(ArticleLink, { href: vod.ciatrArticleUrl, target: "_blank", rel: "noopener", onClick: (event) => {
                    sendLinkClick(event.currentTarget.href, {
                        'widget_type': widgetType,
                        'content_id': generateContentIdParam(title, seasonNumber),
                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                        'category': findParentCategory(title.getParentCategory()).LABEL,
                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                        'genre': generateGenreParam(title.genres),
                        'vod_name': vod.name,
                    });
                }, children: [_jsx(ArticleThumbnail, { src: ogp.thumbnail || '', alt: ogp.title || '', width: 72, height: 72, onError: (event) => {
                            event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                        } }), _jsx(ArticleTitle, { children: ogp.title })] }))] }));
};
const Head = styled.h2 `
  padding: 14px;
  border-left: 5px solid #10c0e6;
  margin: 16px 0;
  background: #f8f8f8;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.4;
  font-feature-settings: 'palt';
  letter-spacing: 0.03em;
  @media ${DEVICES.SMARTPHONE} {
    font-size: 19px;
  }
`;
const Description = styled.p `
  font-size: 1.1rem;
  line-height: 1.5;
  white-space: pre-wrap;
  margin: 24px 0;
`;
const ArticleLink = styled.a `
  color: ${COLORS.SOFT_BLACK};
  text-decoration: none;
  background-color: ${COLORS.LIGHTEST_GRAY};
  border-left: 8px solid ${COLORS.DARKEST_GRAY};
  display: flex;
  align-items: center;
  margin: 24px 0 16px;
  transition: all 0.25s ease-out;
  &:hover {
    opacity: 0.5;
  }
`;
const ArticleThumbnail = styled.img `
  width: 72px;
  height: 72px;
  object-fit: cover;
`;
const ArticleTitle = styled.h4 `
  font-size: 15.4px;
  font-weight: 700;
  display: box;
  line-height: 1.4;
  overflow: hidden;
  box-orient: vertical;
  line-clamp: 3;
  margin: 3.2px 8px;

  @media ${DEVICES.SMARTPHONE} {
    font-size: 14px;
    text-decoration: underline;
  }
`;
export { Section };
