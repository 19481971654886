import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as api from '~/api';
import { Movie } from '~/models';
import { filterAvailableDistributions, filterAvailableVods } from '~/usecases/filter';
import { retrieveDistributions } from '~/usecases/retrieve';
import { sortDistributions } from '~/usecases/sort';
import { sendPageView, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
import { Iframe } from '~/components/parts/iframe';
import { NormalButton } from '~/components/parts/normalButton';
import { AlternativeNote } from '~/components/parts/alternativeNote';
import { findParentCategory, findChildCategory } from '~/constants';
import { FooterTextLink } from '~/components/parts/footerTextLink';
const Widget = (props) => {
    const { title, selectedVodId, seasonNumber, vods, widgetType } = props;
    const distributions = retrieveDistributions(title, seasonNumber);
    const availableDistributions = filterAvailableDistributions(distributions, filterAvailableVods(vods));
    const selectedAvailableDistribution = availableDistributions.find((availableDistribution) => {
        return availableDistribution.vodId === selectedVodId;
    });
    const suggestedDistribution = !selectedAvailableDistribution && availableDistributions.length > 0
        ? sortDistributions(availableDistributions, vods)[0]
        : undefined;
    useEffect(() => {
        sendPageView({
            'widget_type': widgetType,
            'content_id': generateContentIdParam(title, seasonNumber),
            'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
            'category': findParentCategory(title.getParentCategory()).LABEL,
            'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
            'genre': generateGenreParam(title.genres),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (selectedAvailableDistribution) {
        return (_jsxs(Iframe, { children: [_jsx(NormalButton, { widgetType: widgetType, distribution: selectedAvailableDistribution, vods: vods, title: title, seasonNumber: seasonNumber }), _jsx(FooterTextLink, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: availableDistributions })] }));
    }
    return (_jsx(Iframe, { children: _jsx(AlternativeNote, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, selectedVodId: selectedVodId, vods: vods, suggestedDistribution: suggestedDistribution }) }));
};
const getProps = async (dataset) => {
    if (!dataset.vodId && !Number(dataset.vodId)) {
        throw new Error('VOD ID must be specified as number.');
    }
    if (dataset.movieId && Number(dataset.movieId)) {
        return {
            widgetType: dataset.widgetType,
            selectedVodId: Number(dataset.vodId),
            title: await api.findMovie(Number(dataset.movieId)),
            seasonNumber: undefined,
            vods: await api.getAllVodList(),
        };
    }
    if (dataset.tvSeriesId && Number(dataset.tvSeriesId)) {
        if (dataset.seasonNumber && Number(dataset.seasonNumber)) {
            return {
                widgetType: dataset.widgetType,
                selectedVodId: Number(dataset.vodId),
                title: await api.findTvSeries(Number(dataset.tvSeriesId)),
                seasonNumber: Number(dataset.seasonNumber),
                vods: await api.getAllVodList(),
            };
        }
        throw new Error('Season number must be specified as number for TV series.');
    }
    throw new Error('Movie ID or TV series ID must be specified as number.');
};
export { Widget, getProps };
