import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import styled from 'styled-components';
import * as api from '~/api';
import { Movie, TvSeries } from '~/models';
import { filterAvailableDistributions, filterAvailableVods } from '~/usecases/filter';
import { retrieveDistributions, retrieveVodById } from '~/usecases/retrieve';
import { sortDistributions } from '~/usecases/sort';
import { sendPageView, generateContentIdParam, generateGenreParam, sendLinkClick, } from '~/components/functions/analytics';
import { COLORS, findChildCategory } from '~/constants';
import { Iframe } from '~/components/parts/iframe';
import { ButtonListItem } from '~/components/parts/buttonListItem';
import { canWatchFreely, isMobile } from '~/usecases/check';
import { findCategory, findParentCategory } from '~/constants';
import { FooterTextLink } from '~/components/parts/footerTextLink';
const Widget = (props) => {
    const { title, seasonNumber, vods, widgetType } = props;
    const limitToShow = isMobile() ? 3 : 4;
    const distributions = retrieveDistributions(title, seasonNumber);
    const availableVods = filterAvailableVods(vods);
    const sortedDistributions = sortDistributions(filterAvailableDistributions(distributions, availableVods), vods);
    const titleName = title instanceof TvSeries ? title.getNameWithBrackets(seasonNumber) : title.getNameWithBrackets();
    const existsFreeDistribution = sortedDistributions.some((distribution) => {
        const vod = retrieveVodById(vods, distribution.vodId);
        const defaultPlan = vod.getMonthlyDefaultPlan();
        return canWatchFreely(distribution, defaultPlan);
    });
    useEffect(() => {
        sendPageView({
            'widget_type': widgetType,
            'content_id': generateContentIdParam(title, seasonNumber),
            'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
            'category': findParentCategory(title.getParentCategory()).LABEL,
            'sub_category': findCategory(title.category).LABEL,
            'genre': generateGenreParam(title.genres),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!sortedDistributions.length) {
        return (_jsx(Iframe, { children: _jsxs(Wrapper, { children: [_jsxs(Title, { children: [titleName, "\u3092\u914D\u4FE1\u4E2D\u306E\u30B5\u30FC\u30D3\u30B9"] }), _jsxs(NoDistributionText, { children: ["\u73FE\u5728", titleName, "\u3092\u914D\u4FE1\u3057\u3066\u3044\u308B\u30B5\u30FC\u30D3\u30B9\u306F\u3042\u308A\u307E\u305B\u3093\u3002"] }), _jsx(FooterTextLink, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: [] })] }) }));
    }
    return (_jsx(Iframe, { children: _jsxs(Wrapper, { children: [_jsxs(Title, { children: [titleName, "\u3092\u914D\u4FE1\u4E2D\u306E\u30B5\u30FC\u30D3\u30B9"] }), _jsx(List, { "$isMobile": isMobile(), children: sortedDistributions.slice(0, limitToShow).map((distribution, index) => {
                        return (_jsx(ButtonListItem, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, distribution: distribution, vods: filterAvailableVods(vods), rank: index + 1 }, distribution.id));
                    }) }), _jsx(ButtonLink, { href: title instanceof TvSeries ? title.getTitlePageUrl(seasonNumber) : title.getTitlePageUrl(), target: "_blank", rel: "noopener", onClick: (event) => {
                        sendLinkClick(event.currentTarget.href, {
                            'widget_type': widgetType,
                            'content_id': generateContentIdParam(title, seasonNumber),
                            'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                            'category': findParentCategory(title.getParentCategory()).LABEL,
                            'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                            'genre': generateGenreParam(title.genres),
                        });
                    }, children: `${titleName}を${existsFreeDistribution ? '無料で' : ''}配信中のサービスをもっと詳しく` })] }) }));
};
const getProps = async (dataset) => {
    if (dataset.movieId && Number(dataset.movieId)) {
        return {
            widgetType: dataset.widgetType,
            selectedVodId: Number(dataset.vodId),
            title: await api.findMovie(Number(dataset.movieId)),
            seasonNumber: undefined,
            vods: await api.getAllVodList(),
        };
    }
    if (dataset.tvSeriesId && Number(dataset.tvSeriesId)) {
        if (dataset.seasonNumber && Number(dataset.seasonNumber)) {
            return {
                widgetType: dataset.widgetType,
                selectedVodId: Number(dataset.vodId),
                title: await api.findTvSeries(Number(dataset.tvSeriesId)),
                seasonNumber: Number(dataset.seasonNumber),
                vods: await api.getAllVodList(),
            };
        }
        throw new Error('Season number must be specified as number for TV series.');
    }
    throw new Error('Movie ID or TV series ID must be specified as number.');
};
const Wrapper = styled.div `
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${COLORS.LIGHT_GRAY};
`;
const List = styled.div `
  display: grid;
  grid-template-columns: ${({ $isMobile }) => {
    return $isMobile ? `1fr` : `1fr 1fr`;
}};
  gap: 16px;
  padding-bottom: 8px;
`;
const Title = styled.p `
  color: ${COLORS.SOFT_BLACK};
  font-size: 14px;
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 16px;
  margin: 0;
`;
const ButtonLink = styled.a `
  display: flex;
  justify-content: center;
  color: ${COLORS.MEDIUM_GRAY};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding-top: 8px;
  border: solid ${COLORS.LIGHT_GRAY};
  border-width: 1px 0 0;
`;
const NoDistributionText = styled.p `
  color: ${COLORS.MEDIUM_GRAY};
`;
export { Widget, getProps };
