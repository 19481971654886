import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { getAffiiateLink } from '~/usecases/link';
import { COLORS } from '~/constants';
import { LinkWithIcon } from '~/components/parts/linkWithIcon';
const MessageForUnlimitedDistribution = (props) => {
    const { widgetType, title, titleName, numberOfDistributions, vod, distribution, seasonNumber } = props;
    const defaultPlan = vod.getMonthlyDefaultPlan();
    return (_jsxs(NormalText, { children: [(() => {
                if (numberOfDistributions === 1) {
                    return (_jsxs(_Fragment, { children: [`${titleName}を視聴できる`, _jsxs(MarkerText, { children: ["\u552F\u4E00\u306E\u914D\u4FE1\u30B5\u30FC\u30D3\u30B9\u306F", _jsx(LinkWithIcon, { widgetType: widgetType, href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), text: vod.name, vod: vod, title: title, seasonNumber: seasonNumber }), "\u3067\u3059\u3002"] })] }));
                }
                return (_jsxs(_Fragment, { children: [`${titleName}は${numberOfDistributions}サービスで配信されています。`, _jsxs(MarkerText, { children: ["\u305D\u306E\u4E2D\u30671\u756A\u304A\u3059\u3059\u3081\u306E\u30B5\u30FC\u30D3\u30B9\u306F", _jsx(LinkWithIcon, { widgetType: widgetType, href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), text: vod.name, vod: vod, title: title, seasonNumber: seasonNumber }), "\u3067\u3059\u3002"] })] }));
            })(), _jsx("br", {}), _jsx("br", {}), (() => {
                if (defaultPlan.freeTrialPeriod) {
                    return (_jsxs(_Fragment, { children: [`${vod.name}では`, _jsxs(MarkerText, { children: [`${defaultPlan.freeTrialPeriod}の無料トライアルを利用して、`, vod.id === 1 ? '本作を楽しむことができます。' : '本作を実質無料で楽しむことができます。'] })] }));
                }
                return (_jsxs(_Fragment, { children: [`${vod.name}では月額料金を払うことで、`, _jsx(MarkerText, { children: "\u672C\u4F5C\u3092\u898B\u653E\u984C\u3067\u89B3\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002" })] }));
            })()] }));
};
const NormalText = styled.p `
  font-size: 15.4px;
`;
const MarkerText = styled.span `
  background: linear-gradient(transparent 60%, ${COLORS.ONAHAU_BLUE} 0%);
  line-height: 25px;
  font-size: 15.4px;
  font-weight: bolder;
`;
export { MessageForUnlimitedDistribution };
