const PROTOCOL = (process.env.APP_HOST || '').includes('localhost') ? 'http' : 'https';
const ICOMOON = {
    EOT: {
        URL: `${PROTOCOL}://${process.env.APP_HOST}/fonts/icomoon/icomoon.eot`,
    },
    SVG: {
        URL: `${PROTOCOL}://${process.env.APP_HOST}/fonts/icomoon/icomoon.svg`,
    },
    TTF: {
        URL: `${PROTOCOL}://${process.env.APP_HOST}/fonts/icomoon/icomoon.ttf`,
    },
    WOFF: {
        URL: `${PROTOCOL}://${process.env.APP_HOST}/fonts/icomoon/icomoon.woff`,
    },
};
export { ICOMOON };
