import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import * as api from '~/api';
import { Iframe } from '~/components/parts/iframe';
import { LogoImageList } from '~/components/parts/logoImageList';
import { PoweredByFooter } from '~/components/parts/poweredByFooter';
import { DEVICES } from '~/constants';
import { filterAvailableDistributions, filterAvailableVods, filterUnlimitedDistributions, filterRentableDistributions, filterPurchasableDistributions, } from '~/usecases/filter';
import { retrieveDistributions } from '~/usecases/retrieve';
const Widget = (props) => {
    const { widgetType, title, seasonNumber, vods } = props;
    const distributions = retrieveDistributions(title, seasonNumber);
    const availableDistributions = filterAvailableDistributions(distributions, filterAvailableVods(vods));
    const unlimitedDistributions = filterUnlimitedDistributions(availableDistributions);
    const rentableDistributions = filterRentableDistributions(availableDistributions);
    const purchasableDistributions = filterPurchasableDistributions(availableDistributions);
    return (_jsx(Iframe, { children: _jsxs(Container, { children: [unlimitedDistributions.length > 0 && (_jsx(LogoImageList, { label: "\u898B\u653E\u984C\u914D\u4FE1", widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: unlimitedDistributions, vods: vods })), rentableDistributions.length > 0 && (_jsx(LogoImageList, { label: "\u30EC\u30F3\u30BF\u30EB", widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: rentableDistributions, vods: vods })), purchasableDistributions.length > 0 && (_jsx(LogoImageList, { label: "\u8CFC\u5165", widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: purchasableDistributions, vods: vods })), _jsx(PoweredByFooter, { widgetType: widgetType, title: title, seasonNumber: seasonNumber })] }) }));
};
const Container = styled('div') `
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${DEVICES.SMARTPHONE} {
    gap: 10px;
  }
`;
const getProps = async (dataset) => {
    const props = {
        widgetType: dataset.widgetType,
        vods: await api.getAllVodList(),
    };
    if (dataset.movieId && Number(dataset.movieId)) {
        return {
            ...props,
            title: await api.findMovie(Number(dataset.movieId)),
            seasonNumber: undefined,
        };
    }
    if (dataset.tvSeriesId && Number(dataset.tvSeriesId)) {
        if (dataset.seasonNumber && Number(dataset.seasonNumber)) {
            return {
                ...props,
                title: await api.findTvSeries(Number(dataset.tvSeriesId)),
                seasonNumber: Number(dataset.seasonNumber),
            };
        }
        throw new Error('Season number must be specified as number for TV series.');
    }
    throw new Error('Movie ID or TV series ID must be specified as number.');
};
export { Widget, getProps };
