import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { Movie, TvSeries } from '~/models';
import { filterAvailableDistributions, filterAvailableVods, filterPointUnusableDistributions, filterPointUsableDistributions, filterPurchasableDistributions, filterRentableDistributions, filterUnavailableVods, filterUnlimitedDistributions, } from '~/usecases/filter';
import { getAffiiateLink } from '~/usecases/link';
import { retrieveVodById } from '~/usecases/retrieve';
import { COLORS, DEVICES, IMAGES, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
import { FooterTextLink } from '~/components/parts/footerTextLink';
const DistributionTable = (props) => {
    const { title, seasonNumber, distributions, vods, widgetType } = props;
    const titleName = title instanceof TvSeries ? title.getNameWithBrackets(seasonNumber) : title.getNameWithBrackets();
    const unlimitedDistributions = filterUnlimitedDistributions(distributions);
    const pointRentableDistributions = filterPointUsableDistributions(filterRentableDistributions(distributions), vods);
    const nonPointRentableDistributions = filterPointUnusableDistributions(filterRentableDistributions(distributions), vods);
    const purchasableDistributions = filterPurchasableDistributions(distributions);
    const unavailableVods = filterUnavailableVods(vods, distributions);
    const availableDistributions = filterAvailableDistributions(distributions, filterAvailableVods(vods));
    if (distributions.length === 0) {
        return (_jsxs(DistributionListWrapper, { children: [_jsxs(NoStreamBox, { children: [_jsx(BalloonTextWrapper, { children: _jsx(BalloonText, { children: `現在${titleName}を配信中のサービスはありません` }) }), _jsx("div", { children: _jsx(CryingBoyImage, { src: IMAGES.CRYING_BOY.URL, alt: IMAGES.CRYING_BOY.ALT, width: IMAGES.CRYING_BOY.WIDTH, height: IMAGES.CRYING_BOY.HEIGHT }) })] }), _jsx(FooterTextLink, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: [] })] }));
    }
    return (_jsxs(DistributionListWrapper, { children: [_jsx(TableWrapper, { children: _jsx(DistributionListTable, { children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsxs(TableHead, { children: [_jsx("div", { children: _jsx(SymbolImage, { src: IMAGES.DOUBLE_CIRCLE_MARK.URL, alt: IMAGES.DOUBLE_CIRCLE_MARK.ALT, width: IMAGES.DOUBLE_CIRCLE_MARK.WIDTH, height: IMAGES.DOUBLE_CIRCLE_MARK.HEIGHT }) }), _jsx(ColumnDiv, { children: "\u898B\u653E\u984C" })] }), _jsx(TableData, { children: _jsx(DivFlex, { children: unlimitedDistributions.length !== 0 ? (unlimitedDistributions.map((distribution) => {
                                                const vod = retrieveVodById(vods, distribution.vodId);
                                                const defaultPlan = vod.getMonthlyDefaultPlan();
                                                return (_jsxs(ServiceWrapper, { children: [_jsx("div", { children: _jsx(DecorationNoneA, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                                                    sendLinkClick(event.currentTarget.href, {
                                                                        'widget_type': widgetType,
                                                                        'content_id': generateContentIdParam(title, seasonNumber),
                                                                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                                                        'category': findParentCategory(title.getParentCategory()).LABEL,
                                                                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                                                        'genre': generateGenreParam(title.genres),
                                                                        'vod_name': vod.name,
                                                                    });
                                                                }, children: vod.id === distributions[0].vodId ? (_jsxs(RecommendServiceWrapper, { children: [_jsx(RecommendServiceLogo, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                                                                event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                                                            } }), _jsx(RecommendServiceLogoMark, { src: IMAGES.CROWN_LABEL.URL, alt: IMAGES.CROWN_LABEL.ALT, width: IMAGES.CROWN_LABEL.WIDTH, height: IMAGES.CROWN_LABEL.HEIGHT })] })) : (_jsx(ServiceLogo, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                                                        event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                                                    } })) }) }), _jsx("div", { children: defaultPlan.isFree() || defaultPlan.hasFreeTrial()
                                                                ? `${defaultPlan.freeTrialPeriod || ''}無料`
                                                                : '無料期間なし' }), _jsx(ServiceButtonWrapper, { children: _jsx(ServiceButtonLink, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                                                    sendLinkClick(event.currentTarget.href, {
                                                                        'widget_type': widgetType,
                                                                        'content_id': generateContentIdParam(title, seasonNumber),
                                                                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                                                        'category': findParentCategory(title.getParentCategory()).LABEL,
                                                                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                                                        'genre': generateGenreParam(title.genres),
                                                                        'vod_name': vod.name,
                                                                    });
                                                                }, children: "\u4ECA\u3059\u3050\u89B3\u308B" }) })] }, vod.id));
                                            })) : (_jsx(NoServiceSpan, { children: "\u73FE\u5728\u63D0\u4F9B\u4E2D\u306E\u30B5\u30FC\u30D3\u30B9\u306F\u3042\u308A\u307E\u305B\u3093\u3002" })) }) })] }), _jsxs("tr", { children: [_jsxs(TableHead, { children: [_jsx("div", { children: _jsx(SymbolImage, { src: IMAGES.CIRCLE_MARK.URL, alt: IMAGES.CIRCLE_MARK.ALT, width: IMAGES.CIRCLE_MARK.WIDTH, height: IMAGES.CIRCLE_MARK.HEIGHT }) }), _jsx(ColumnDiv, { children: "\u30EC\u30F3\u30BF\u30EB" }), _jsxs(PointText, { children: ["\u521D\u56DE\u767B\u9332\u6642", _jsx("br", {}), "\u30DD\u30A4\u30F3\u30C8\u3042\u308A"] })] }), _jsx(TableData, { children: _jsx(DivFlex, { children: pointRentableDistributions.length !== 0 ? (pointRentableDistributions.map((distribution) => {
                                                const vod = retrieveVodById(vods, distribution.vodId);
                                                const defaultPlan = vod.getMonthlyDefaultPlan();
                                                return (_jsxs(ServiceWrapper, { children: [_jsx("div", { children: _jsx(DecorationNoneA, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                                                    sendLinkClick(event.currentTarget.href, {
                                                                        'widget_type': widgetType,
                                                                        'content_id': generateContentIdParam(title, seasonNumber),
                                                                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                                                        'category': findParentCategory(title.getParentCategory()).LABEL,
                                                                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                                                        'genre': generateGenreParam(title.genres),
                                                                        'vod_name': vod.name,
                                                                    });
                                                                }, children: vod.id === distributions[0].vodId ? (_jsxs(RecommendServiceWrapper, { children: [_jsx(RecommendServiceLogo, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                                                                event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                                                            } }), _jsx(RecommendServiceLogoMark, { src: IMAGES.CROWN_LABEL.URL, alt: IMAGES.CROWN_LABEL.ALT, width: IMAGES.CROWN_LABEL.WIDTH, height: IMAGES.CROWN_LABEL.HEIGHT })] })) : (_jsx(ServiceLogo, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                                                        event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                                                    } })) }) }), _jsx("div", { children: (() => {
                                                                if (defaultPlan.hasFreeTrial()) {
                                                                    return (_jsxs(_Fragment, { children: [defaultPlan.freeTrialPeriod, "\u7121\u6599", vod.id === 7 && (_jsxs(_Fragment, { children: [_jsx("br", {}), "\u5B85\u914D\u30EC\u30F3\u30BF\u30EB"] }))] }));
                                                                }
                                                                return _jsx(_Fragment, { children: "\u7121\u6599\u671F\u9593\u306A\u3057" });
                                                            })() }), _jsx(ServiceButtonWrapper, { children: _jsx(ServiceButtonLink, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                                                    sendLinkClick(event.currentTarget.href, {
                                                                        'widget_type': widgetType,
                                                                        'content_id': generateContentIdParam(title, seasonNumber),
                                                                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                                                        'category': findParentCategory(title.getParentCategory()).LABEL,
                                                                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                                                        'genre': generateGenreParam(title.genres),
                                                                        'vod_name': vod.name,
                                                                    });
                                                                }, children: "\u4ECA\u3059\u3050\u89B3\u308B" }) })] }, vod.id));
                                            })) : (_jsx(NoServiceSpan, { children: "\u73FE\u5728\u63D0\u4F9B\u4E2D\u306E\u30B5\u30FC\u30D3\u30B9\u306F\u3042\u308A\u307E\u305B\u3093\u3002" })) }) })] }), _jsxs("tr", { children: [_jsxs(TableHead, { children: [_jsx("div", { children: _jsx(SymbolImage, { src: IMAGES.TRIANGLE_MARK.URL, alt: IMAGES.TRIANGLE_MARK.ALT, width: IMAGES.TRIANGLE_MARK.WIDTH, height: IMAGES.TRIANGLE_MARK.HEIGHT }) }), _jsx(ColumnDiv, { children: "\u30EC\u30F3\u30BF\u30EB" }), _jsxs(PointText, { children: ["\u521D\u56DE\u767B\u9332\u6642", _jsx("br", {}), "\u30DD\u30A4\u30F3\u30C8\u306A\u3057"] })] }), _jsx(TableData, { children: _jsx(DivFlex, { children: nonPointRentableDistributions.length !== 0 ? (nonPointRentableDistributions.map((distribution) => {
                                                const vod = retrieveVodById(vods, distribution.vodId);
                                                const defaultPlan = vod.getMonthlyDefaultPlan();
                                                return (_jsxs(ServiceWrapper, { children: [_jsx("div", { children: _jsx(DecorationNoneA, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                                                    sendLinkClick(event.currentTarget.href, {
                                                                        'widget_type': widgetType,
                                                                        'content_id': generateContentIdParam(title, seasonNumber),
                                                                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                                                        'category': findParentCategory(title.getParentCategory()).LABEL,
                                                                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                                                        'genre': generateGenreParam(title.genres),
                                                                        'vod_name': vod.name,
                                                                    });
                                                                }, children: vod.id === distributions[0].vodId ? (_jsxs(RecommendServiceWrapper, { children: [_jsx(RecommendServiceLogo, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                                                                event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                                                            } }), _jsx(RecommendServiceLogoMark, { src: IMAGES.CROWN_LABEL.URL, alt: IMAGES.CROWN_LABEL.ALT, width: IMAGES.CROWN_LABEL.WIDTH, height: IMAGES.CROWN_LABEL.HEIGHT })] })) : (_jsx(ServiceLogo, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                                                        event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                                                    } })) }) }), _jsx("div", { children: (() => {
                                                                if (defaultPlan.hasFreeTrial()) {
                                                                    return (_jsxs(_Fragment, { children: [defaultPlan.freeTrialPeriod, "\u7121\u6599"] }));
                                                                }
                                                                return _jsx(_Fragment, { children: "\u7121\u6599\u671F\u9593\u306A\u3057" });
                                                            })() }), _jsx(ServiceButtonWrapper, { children: _jsx(ServiceButtonLink, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                                                    sendLinkClick(event.currentTarget.href, {
                                                                        'widget_type': widgetType,
                                                                        'content_id': generateContentIdParam(title, seasonNumber),
                                                                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                                                        'category': findParentCategory(title.getParentCategory()).LABEL,
                                                                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                                                        'genre': generateGenreParam(title.genres),
                                                                        'vod_name': vod.name,
                                                                    });
                                                                }, children: "\u4ECA\u3059\u3050\u89B3\u308B" }) })] }, vod.id));
                                            })) : (_jsx(NoServiceSpan, { children: "\u73FE\u5728\u63D0\u4F9B\u4E2D\u306E\u30B5\u30FC\u30D3\u30B9\u306F\u3042\u308A\u307E\u305B\u3093\u3002" })) }) })] }), _jsxs("tr", { children: [_jsxs(TableHead, { children: [_jsx("div", { children: _jsx(SymbolImage, { src: IMAGES.TRIANGLE_MARK.URL, alt: IMAGES.TRIANGLE_MARK.ALT, width: IMAGES.TRIANGLE_MARK.WIDTH, height: IMAGES.TRIANGLE_MARK.HEIGHT }) }), _jsx(ColumnDiv, { children: "\u8CFC\u5165" })] }), _jsx(TableData, { children: _jsx(DivFlex, { children: purchasableDistributions.length !== 0 ? (purchasableDistributions.map((distribution) => {
                                                const vod = retrieveVodById(vods, distribution.vodId);
                                                return (_jsxs(ServiceWrapper, { children: [_jsx("div", { children: _jsx(DecorationNoneA, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                                                    sendLinkClick(event.currentTarget.href, {
                                                                        'widget_type': widgetType,
                                                                        'content_id': generateContentIdParam(title, seasonNumber),
                                                                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                                                        'category': findParentCategory(title.getParentCategory()).LABEL,
                                                                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                                                        'genre': generateGenreParam(title.genres),
                                                                        'vod_name': vod.name,
                                                                    });
                                                                }, children: vod.id === distributions[0].vodId ? (_jsxs(RecommendServiceWrapper, { children: [_jsx(RecommendServiceLogo, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                                                                event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                                                            } }), _jsx(RecommendServiceLogoMark, { src: IMAGES.CROWN_LABEL.URL, alt: IMAGES.CROWN_LABEL.ALT, width: IMAGES.CROWN_LABEL.WIDTH, height: IMAGES.CROWN_LABEL.HEIGHT })] })) : (_jsx(ServiceLogo, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                                                        event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                                                    } })) }) }), _jsx(ServiceButtonWrapper, { children: _jsx(ServiceButtonLink, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                                                    sendLinkClick(event.currentTarget.href, {
                                                                        'widget_type': widgetType,
                                                                        'content_id': generateContentIdParam(title, seasonNumber),
                                                                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                                                        'category': findParentCategory(title.getParentCategory()).LABEL,
                                                                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                                                        'genre': generateGenreParam(title.genres),
                                                                        'vod_name': vod.name,
                                                                    });
                                                                }, children: "\u4ECA\u3059\u3050\u89B3\u308B" }) })] }, vod.id));
                                            })) : (_jsx(NoServiceSpan, { children: "\u73FE\u5728\u63D0\u4F9B\u4E2D\u306E\u30B5\u30FC\u30D3\u30B9\u306F\u3042\u308A\u307E\u305B\u3093\u3002" })) }) })] }), _jsxs("tr", { children: [_jsxs(TableHead, { style: { height: 'auto' }, children: [_jsx("div", { children: _jsx(SymbolImage, { src: IMAGES.CROSS_MARK.URL, alt: IMAGES.CROSS_MARK.ALT, width: IMAGES.CROSS_MARK.WIDTH, height: IMAGES.CROSS_MARK.HEIGHT }) }), _jsx(ColumnDiv, { children: "\u914D\u4FE1\u306A\u3057" })] }), _jsx(TableData, { children: _jsx(DivFlex, { children: unavailableVods.length > 0 ? (unavailableVods.map((vod) => {
                                                return (_jsx(ServiceNoStreamWrapper, { children: _jsx("a", { href: getAffiiateLink(vod, undefined, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                                            sendLinkClick(event.currentTarget.href, {
                                                                'widget_type': widgetType,
                                                                'content_id': generateContentIdParam(title, seasonNumber),
                                                                'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                                                'category': findParentCategory(title.getParentCategory()).LABEL,
                                                                'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                                                'genre': generateGenreParam(title.genres),
                                                                'vod_name': vod.name,
                                                            });
                                                        }, children: _jsx(ServiceNoStreamLogo, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                                                event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                                            } }) }) }, vod.id));
                                            })) : (_jsx(NoServiceSpan, { children: "\u73FE\u5728\u63D0\u4F9B\u4E2D\u3067\u306A\u3044\u30B5\u30FC\u30D3\u30B9\u306F\u3042\u308A\u307E\u305B\u3093\u3002" })) }) })] })] }) }) }), _jsxs(PrecautionaryComment, { children: ["\u203B\u8868\u306F\u6A2A\u306B\u30B9\u30AF\u30ED\u30FC\u30EB\u53EF\u80FD\u3067\u3059\u3002", _jsx("br", {}), "\u203BTSUTAYA DISCAS\u306B\u306F\u521D\u56DE\u767B\u9332\u6642\u306E\u30DD\u30A4\u30F3\u30C8\u4ED8\u4E0E\u306F\u3042\u308A\u307E\u305B\u3093\u3002"] }), _jsx(FooterTextLink, { widgetType: widgetType, title: title, distributions: availableDistributions, seasonNumber: seasonNumber })] }));
};
const DistributionListWrapper = styled.div `
  max-width: 1080px;
  margin: 0 auto;
  padding: 8px 0;
`;
const NoStreamBox = styled.div `
  background: ${COLORS.PALE_BLUE_GREEN};
  text-align: center;
  padding: 1.2rem 1rem;
  border-radius: 4px;
  margin: 0 auto;
`;
const BalloonTextWrapper = styled.div `
  position: relative;
  display: inline-block;
  margin-bottom: 1.5rem;
  padding: 1rem 1rem;
  min-width: 120px;
  max-width: 100%;
  background: ${COLORS.PURE_WHITE};
  border-radius: 8px;
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border: 10px solid transparent;
    border-top: 12px solid ${COLORS.PURE_WHITE};
  }
`;
const BalloonText = styled.p `
  color: ${COLORS.DARK_BLUE_GREEN};
  line-height: 1.5;
  margin: 0;
  font-size: 1.1rem;
  font-weight: bold;
`;
const CryingBoyImage = styled.img `
  width: 50px;
  height: 50px;
`;
const DecorationNoneA = styled.a `
  text-decoration: none;
`;
const TableWrapper = styled.div `
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: ${COLORS.VERY_LIGHT_GRAY};
  }
  &::-webkit-scrollbar-thumb {
    background: ${COLORS.LIGHT_GRAY};
  }
`;
const DistributionListTable = styled.table `
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
`;
const SymbolImage = styled.img `
  height: 36px;
  width: 36px;
  @media ${DEVICES.SMARTPHONE} {
    height: 32px;
    width: 32px;
  }
`;
const DivFlex = styled.div `
  display: flex;
`;
const PrecautionaryComment = styled.p `
  line-height: 1.5;
`;
const TableHead = styled.th `
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid ${COLORS.PURE_WHITE};
  width: 84px;
  min-width: 84px;
  height: 77px;
  background: ${COLORS.BLUE_GREEN};
  color: ${COLORS.PURE_WHITE};
  padding: 8px 0;
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  @media ${DEVICES.SMARTPHONE} {
    width: 80px;
    min-width: 80px;
    height: 60px;
  }
`;
const TableData = styled.td `
  border-bottom: 1px solid ${COLORS.PURE_WHITE};
  padding-left: 10px;
  background: #e8f9f6;
`;
const ColumnDiv = styled.div `
  font-size: 14px;
  margin: 4px 0;

  @media ${DEVICES.SMARTPHONE} {
    font-size: 13px;
  }
`;
const PointText = styled.div `
  font-size: 11px;
  margin-top: 3px;
  line-height: 1.4;
  color: #e8f9f6;
`;
const ServiceWrapper = styled.div `
  text-align: center;
  padding: 10px 10px 14px 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  font-size: 14px;
  color: ${COLORS.DARK_BLUE_GREEN};
  line-height: 1.2;
  @media ${DEVICES.SMARTPHONE} {
    font-size: 13px;
  }
`;
const ServiceNoStreamWrapper = styled.div `
  padding: 4px 10px 4px 0;
`;
const ServiceLogo = styled.img `
  width: 90px;
  min-width: 90px;
  height: 60px;
  border: 0.5px solid #ddd;
  margin-bottom: 4px;
  @media ${DEVICES.SMARTPHONE} {
    width: 81px;
    min-width: 81px;
    height: 54px;
  }
`;
const ServiceNoStreamLogo = styled.img `
  width: 81px;
  min-width: 81px;
  height: 54px;
  border: 0.5px solid #ddd;
  @media ${DEVICES.SMARTPHONE} {
    width: 72px;
    min-width: 72px;
    height: 48px;
  }
`;
const RecommendServiceWrapper = styled.div `
  position: relative;
`;
const RecommendServiceLogoMark = styled.img `
  width: 28px;
  height: 28px;
  position: absolute !important;
  top: 0;
  right: 0;
  @media ${DEVICES.SMARTPHONE} {
    width: 24px;
    height: 24px;
  }
`;
const RecommendServiceLogo = styled.img `
  width: 90px;
  min-width: 90px;
  height: 60px;
  border: 0.5px solid ${COLORS.CIATR_RED};
  margin-bottom: 4px;
  @media ${DEVICES.SMARTPHONE} {
    width: 81px;
    min-width: 81px;
    height: 54px;
  }
`;
const ServiceButtonWrapper = styled.div `
  text-align: center;
  margin-top: 4px;
`;
const ServiceButtonLink = styled.a `
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 4px 0 ${COLORS.SHADOWY_RED};
  line-height: 1.5;
  color: ${COLORS.PURE_WHITE};
  font-weight: 600;
  background: ${COLORS.CIATR_RED};
  border-radius: 7px;
  padding: 7px 15px;
  font-size: 12px;
  @media ${DEVICES.SMARTPHONE} {
    font-size: 11px;
  }
`;
const NoServiceSpan = styled.span `
  color: ${COLORS.DARK_BLUE_GREEN};
  font-size: 14px;
`;
export { DistributionTable };
