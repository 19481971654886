import * as ReactDOM from 'react-dom/client';
import * as api from '~/api';
import * as googleAnalytics from '~/googleAnalytics';
import { apiKey, analyticsId } from './config';
import { createComponent } from './controller';
let isInitialized = false;
const init = () => {
    // Init API with config.
    googleAnalytics.init(process.env.GOOGLE_ANALYTICS_ID || '', analyticsId);
    api.init({
        apiEndpoint: process.env.API_ENDPOINT || '',
        apiKey: apiKey,
    });
    isInitialized = true;
};
const getContainers = () => {
    // Get HTML div elements in where widgets are displayed.
    return Array.from(document.getElementsByClassName('onescreen-widget-container'))
        .filter((element) => {
        return element instanceof HTMLDivElement;
    })
        .filter((element) => {
        // Get only empty div elements in order to prevent re-rendering.
        return element.children.length === 0;
    });
};
const render = async () => {
    // Init application if it's not initialized yet.
    if (!isInitialized) {
        init();
    }
    const containers = getContainers();
    containers.forEach(async (container, index) => {
        try {
            // Create React component with data from API.
            const widget = await createComponent(container.dataset);
            // Create root container from the target element and render a widget.
            ReactDOM.createRoot(container, { identifierPrefix: String(index + 1) }).render(widget);
        }
        catch (error) {
            window.console.error(`[1Screen Widget] ${error instanceof Error ? error.message : error}`);
        }
    });
};
export { render };
