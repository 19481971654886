class VodPlan {
    constructor(props) {
        this.getPrice = () => {
            const taxRate = 1.1;
            return Math.floor(this.priceWithoutTax * taxRate);
        };
        this.isFree = () => {
            return this.priceWithoutTax === 0;
        };
        this.hasFreeTrial = () => {
            return !!this.freeTrialPeriod && this.freeTrialPeriod !== 'なし';
        };
        this.hasInitialPoint = () => {
            return !!this.initialPoint && this.initialPoint > 0;
        };
        this.id = props.id;
        this.vodId = props.vodId;
        this.name = props.name || null;
        this.priceWithoutTax = props.priceWithoutTax;
        this.shouldDisplayWithTax = props.shouldDisplayWithTax;
        this.period = props.period;
        this.isDefault = props.isDefault;
        this.freeTrialPeriod = props.freeTrialPeriod || null;
        this.numberOfAvailableDevices = props.numberOfAvailableDevices || null;
        this.initialPoint = props.initialPoint || null;
    }
}
export { VodPlan };
