import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Movie, TvSeries } from '~/models';
import { retrieveVodById } from '~/usecases/retrieve';
import { COLORS, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
import { FooterTextLink } from '~/components/parts/footerTextLink';
const AlternativeNote = (props) => {
    const { title, seasonNumber, selectedVodId, vods, suggestedDistribution, widgetType } = props;
    const titleName = title instanceof TvSeries ? title.getNameWithBrackets(seasonNumber) : title.getNameWithBrackets();
    const selectedVod = retrieveVodById(vods, selectedVodId);
    const suggestedVod = suggestedDistribution ? retrieveVodById(vods, suggestedDistribution.vodId) : undefined;
    return (_jsxs(Container, { children: [_jsxs(Note, { children: [_jsx("span", { children: `${selectedVod.name}では、現在${titleName}を配信していません。` }), suggestedDistribution && suggestedVod && (_jsxs(_Fragment, { children: [_jsx(SuggestedVodLink, { href: suggestedVod.commonLandingPageUrl, target: "_blank", rel: "noopener noreferrer", onClick: (event) => {
                                    sendLinkClick(event.currentTarget.href, {
                                        'widget_type': widgetType,
                                        'content_id': generateContentIdParam(title, undefined),
                                        'content_name': title instanceof Movie ? title.getName() : title.getName(undefined),
                                        'category': findParentCategory(title.getParentCategory()).LABEL,
                                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                        'genre': generateGenreParam(title.genres),
                                        'vod_name': suggestedVod.name,
                                    });
                                }, children: suggestedVod.name }), _jsx("span", { children: "\u3067\u306F\u914D\u4FE1\u4E2D\u3067\u3059\u3002" })] }))] }), _jsx(FooterTextLink, { widgetType: widgetType, title: title, distributions: suggestedDistribution ? [suggestedDistribution] : [], seasonNumber: seasonNumber })] }));
};
const Container = styled.div `
  font-size: 15px;
  line-height: 1.5;
`;
const Note = styled.div `
  color: ${COLORS.DARK_BLUE_GREEN};
  padding: 16px;
  background-color: ${COLORS.PALE_BLUE_GREEN};
  border-radius: 4px;
`;
const SuggestedVodLink = styled.a `
  color: ${COLORS.DARK_BLUE_GREEN};
  text-decoration: underline;
`;
export { AlternativeNote };
