const apiKey = window.onescreen?.apiKey || '';
const analyticsId = window.onescreen?.analyticsId || '';
const affiliateMedia = [
    'ciatr.jp',
    'matome-admin.ciatr.jp',
    'matome-view.viviane-dev.com',
    'matome-admin.viviane-dev.com',
    'www.pintscope.com',
    'dev.pintscope.com',
    'www.cinemacafe.net',
    's.cinemacafe.net',
    'otomoshuma.com',
    'natalie.mu',
];
export { apiKey, analyticsId, affiliateMedia };
