import { Movie, TvSeries } from '~/models';
import { getDateText } from '~/usecases/date';
import { isSameDay } from 'date-fns';
import { sortTvSeasonAndDistributionPairs } from './sort';
const distributionState = ['ongoing', 'startingSoon', 'endingSoon'];
const retrieveVodById = (vods, vodId) => {
    const result = vods.find((vod) => {
        return vod.id === vodId;
    });
    if (!result) {
        throw new Error(`Unknown VOD [ID: ${vodId}] is given.`);
    }
    return result;
};
const retrieveDistributions = (title, seasonNumber) => {
    if (title instanceof Movie) {
        return title.distributions;
    }
    if (title instanceof TvSeries) {
        const tvSeason = title.tvSeasons.find((season) => {
            return season.seasonNumber === seasonNumber;
        });
        return tvSeason ? tvSeason.distributions : [];
    }
    return [];
};
const retrieveRankingTitle = (titles, rankingNumber) => {
    const rankingTitles = titles.filter((title) => {
        if (title instanceof Movie) {
            return retrieveDistributions(title).length > 0;
        }
        if (title instanceof TvSeries) {
            return title.tvSeasons.some((tvSeason) => {
                return retrieveDistributions(title, tvSeason.seasonNumber).length > 0;
            });
        }
        return false;
    });
    return rankingTitles.length >= rankingNumber ? rankingTitles[rankingNumber - 1] : null;
};
const retrieveTvSeasonAndDistributionPairsPerFormatByVod = (title, vod) => {
    const { id } = vod;
    const tvSeasonAndDistributionPairs = {
        ongoing: [],
        startingSoon: [],
        endingSoon: [],
    };
    const updateTvSeasonAndDistributionPairs = (distribution, tvSeason, state) => {
        tvSeasonAndDistributionPairs[state] = [...tvSeasonAndDistributionPairs[state], { distribution, tvSeason }];
    };
    (title.tvSeasons ?? []).forEach((tvSeason) => {
        const distribution = (tvSeason.distributions ?? []).find(({ vodId }) => {
            return vodId === id;
        });
        if (distribution && distribution.isStartingSoon()) {
            updateTvSeasonAndDistributionPairs(distribution, tvSeason, 'startingSoon');
        }
        else if (distribution && distribution.isAvailableNow() && !distribution.isEndingSoon()) {
            updateTvSeasonAndDistributionPairs(distribution, tvSeason, 'ongoing');
        }
        else if (distribution && distribution.isEndingSoon()) {
            updateTvSeasonAndDistributionPairs(distribution, tvSeason, 'endingSoon');
        }
    });
    return tvSeasonAndDistributionPairs;
};
const retrieveEndingSoonText = (distribution, vod, title) => {
    if (title instanceof Movie && distribution?.endsAt && distribution.isEndingSoon()) {
        return `${getDateText(new Date(distribution.endsAt), 'yyyy年M月d日')}に配信終了`;
    }
    if (title instanceof TvSeries && vod) {
        const endingSoonTvSeasonAndDistributionPairs = retrieveTvSeasonAndDistributionPairsPerFormatByVod(title, vod).endingSoon;
        if (endingSoonTvSeasonAndDistributionPairs.length === 0) {
            return '';
        }
        const sortedTvSeasonAndDistributionPairsByEndsAt = sortTvSeasonAndDistributionPairs(endingSoonTvSeasonAndDistributionPairs, 'endsAt', 'asc');
        const soonestEndsAt = sortedTvSeasonAndDistributionPairsByEndsAt[0].distribution.endsAt
            ? new Date(sortedTvSeasonAndDistributionPairsByEndsAt[0].distribution.endsAt)
            : '';
        if (!soonestEndsAt) {
            return '';
        }
        const soonestEndsAtTvSeasonAndDistributionPairs = sortedTvSeasonAndDistributionPairsByEndsAt.filter(({ distribution: { endsAt } }) => {
            return endsAt && isSameDay(new Date(endsAt), soonestEndsAt);
        });
        const sortedTvSeasonAndDistributionPairsByTvSeasonAsc = sortTvSeasonAndDistributionPairs(soonestEndsAtTvSeasonAndDistributionPairs, 'seasonNumber', 'asc');
        if (sortedTvSeasonAndDistributionPairsByTvSeasonAsc.length &&
            sortedTvSeasonAndDistributionPairsByTvSeasonAsc[0].distribution.endsAt) {
            return `${getDateText(soonestEndsAt, 'yyyy年M月d日')}に${sortedTvSeasonAndDistributionPairsByTvSeasonAsc.length}シーズン配信終了`;
        }
    }
    return '';
};
const retrieveDistributionsCountByVodId = (title, vodId) => {
    if (title instanceof Movie) {
        return (title.distributions ?? []).filter((distribution) => {
            return distribution.vodId === vodId && distribution.isAvailableNow();
        }).length;
    }
    return (title.tvSeasons ?? [])
        .flatMap((tvSeason) => {
        return tvSeason.distributions ?? [];
    })
        .filter((distribution) => {
        return distribution.vodId === vodId && distribution.isAvailableNow();
    }).length;
};
export { retrieveVodById, retrieveDistributions, retrieveRankingTitle, retrieveEndingSoonText, retrieveDistributionsCountByVodId, };
