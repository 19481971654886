import { TvSeason } from '~/models/tvSeason';
import { Poster } from '~/models/poster';
import { URLS, CATEGORIES } from '~/constants';
const tvSeriesCategories = [
    CATEGORIES.TV_DRAMA_SERIES.SLUG,
    CATEGORIES.TV_ANIME_SERIES.SLUG,
    CATEGORIES.TV_VARIETY_SERIES.SLUG,
];
class TvSeries {
    constructor(props) {
        this.getType = () => {
            return 'tv';
        };
        this.getName = (seasonNumber) => {
            const selectedTvSeason = this.tvSeasons.find((tvSeason) => {
                return tvSeason.seasonNumber === seasonNumber;
            });
            return ((this.japaneseName || `${this.originalName}(原題)`) + (selectedTvSeason ? ` ${selectedTvSeason.getName()}` : ''));
        };
        this.getNameWithBrackets = (seasonNumber) => {
            const hasMoreSeasons = this.tvSeasons.some((tvSeason) => {
                return tvSeason.seasonNumber >= 2;
            });
            const selectedTvSeason = this.tvSeasons.find((tvSeason) => {
                return tvSeason.seasonNumber === seasonNumber;
            });
            if ((seasonNumber === 1 && !hasMoreSeasons) || !selectedTvSeason) {
                return '『' + (this.japaneseName || this.originalName + '(原題)') + '』';
            }
            return '『' + (this.japaneseName || this.originalName + '(原題)') + ' ' + selectedTvSeason.getName() + '』';
        };
        this.getTitlePageUrl = (seasonNumber) => {
            return `${URLS.PROD.ONESCREEN_WEB_APP}/tv/${this.id}${seasonNumber ? `/season/${seasonNumber}` : ''}`;
        };
        this.getPosterUrl = () => {
            const poster = this.posters.find((poster) => {
                return poster.country === 'JP';
            }) || this.posters[0];
            if (!poster) {
                return '';
            }
            return `${URLS.PROD.ONESCREEN_IMAGES}/${poster.path.startsWith('/') ? poster.path.slice(1) : poster.path}`;
        };
        this.getParentCategory = () => {
            if (this.category === CATEGORIES.TV_DRAMA_SERIES.SLUG) {
                return 'drama';
            }
            else if (this.category === CATEGORIES.TV_ANIME_SERIES.SLUG) {
                return 'anime';
            }
            else {
                return 'variety';
            }
        };
        this.id = props.id;
        this.category = props.category;
        this.originalName = props.originalName;
        this.japaneseName = props.japaneseName || null;
        this.japaneseTagline = props.japaneseTagline || null;
        this.japaneseOverview = props.japaneseOverview || null;
        this.originalLanguage = props.originalLanguage;
        this.productionCountries = props.productionCountries || [];
        this.firstAirDate = props.firstAirDate ?? null;
        this.runningTime = props.runningTime ?? null;
        this.originalHomepageUrl = props.originalHomepageUrl || null;
        this.japaneseHomepageUrl = props.japaneseHomepageUrl || null;
        this.tvSeasons =
            props.tvSeasons?.map((childProps) => {
                return new TvSeason(childProps);
            }) || [];
        this.posters =
            props.posters?.map((childProps) => {
                return new Poster(childProps);
            }) || [];
        this.genres = props.genres ?? [];
    }
}
export { TvSeries };
