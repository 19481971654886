import { jsx as _jsx } from "react/jsx-runtime";
import { keyframes, styled } from 'styled-components';
import { COLORS } from '~/constants';
const SpeechBallonBox = ({ labelText }) => {
    return (_jsx(SpeechBallonBoxContainer, { children: _jsx(Text, { children: labelText }) }));
};
const Text = styled.span `
  color: ${COLORS.BLACK};
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  padding: 16px;
  text-align: center;
`;
const SpeechBallonAnimation = keyframes `
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(0);
  }
`;
const SpeechBallonBoxContainer = styled.div `
  position: relative;
  display: block;
  max-width: 450px;
  margin: 0 auto 24px;
  border: 3px solid ${COLORS.LIGHTER_GRAY};
  border-radius: 3px;
  background-color: ${COLORS.PURE_WHITE};
  animation: ${SpeechBallonAnimation} 0.5s infinite alternate linear;

  &:before {
    content: '';
    position: absolute;
    bottom: -24px;
    left: 50%;
    margin-left: -15px;
    border: 12px solid transparent;
    border-top-color: ${COLORS.PURE_WHITE};
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 50%;
    margin-left: -17px;
    border: 14px solid transparent;
    border-top-color: ${COLORS.LIGHTER_GRAY};
    z-index: 1;
  }
`;
export { SpeechBallonBox };
