import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useFrame } from 'react-frame-component';
import { StyleSheetManager } from 'styled-components';
import { GlobalStyle } from './style';
const Content = (props) => {
    const { children, setIframeHeight } = props;
    const { document } = useFrame();
    useEffect(() => {
        const height = document?.body?.scrollHeight ?? 0;
        if (height !== 0) {
            setIframeHeight(height);
        }
    });
    return (_jsx(StyleSheetManager, { target: document?.head, children: _jsxs(_Fragment, { children: [_jsx(GlobalStyle, {}), children] }) }));
};
export { Content };
