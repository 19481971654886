import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
import { DistributionData } from '~/components/parts/seriesTable/distributionData';
import { TitleHeader } from '~/components/parts/seriesTable/titleHeader';
import { VodHeader } from '~/components/parts/seriesTable/vodHeader';
import { COLORS, DEVICES } from '~/constants';
import { FooterTextLink } from '~/components/parts/footerTextLink';
const SeriesTable = (props) => {
    const { vods, tvSeries, widgetType, isPullDownOpen, setIsPullDownOpen } = props;
    const titleHeaders = tvSeries.tvSeasons.map((tvSeason) => {
        const distributionForTitleHeader = tvSeason.distributions.find((distribution) => {
            return distribution.vodId === vods[0].id;
        });
        return (_jsx(TitleHeader, { widgetType: widgetType, vods: vods, tvSeries: tvSeries, tvSeason: tvSeason, distribution: distributionForTitleHeader }, tvSeason.id));
    });
    const distributionRows = vods.map((vod, index) => {
        const distributionForVodHeader = tvSeries.tvSeasons[0].distributions.find((distribution) => {
            return distribution.vodId === vod.id;
        });
        if (!isPullDownOpen && index >= 5) {
            return null;
        }
        return (_jsxs("tr", { children: [_jsx(VodHeader, { widgetType: widgetType, tvSeries: tvSeries, vod: vod, rank: index + 1, distribution: distributionForVodHeader }), tvSeries.tvSeasons.map((tvSeason) => {
                    const distributionForDistributionData = tvSeason.distributions.find((distribution) => {
                        return distribution.vodId === vod.id;
                    });
                    return (_jsx(DistributionData, { widgetType: widgetType, tvSeries: tvSeries, seasonNumber: tvSeason.seasonNumber, rank: index + 1, vod: vod, distribution: distributionForDistributionData }, tvSeason.id));
                })] }, vod.id));
    });
    return (_jsxs(Wrapper, { children: [_jsxs(FixedHeaderTable, { "$isScrollable": tvSeries.tvSeasons.length > 5, children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx(FixedTableHeader, {}), titleHeaders] }) }), _jsx("tbody", { children: distributionRows })] }), vods.length > 5 && (_jsx(AccordionWrapper, { children: _jsxs(AccordionLabel, { children: [isPullDownOpen ? '配信状況を閉じる▲' : '配信状況を全部見る▼', _jsx(AccordionCheckbox, { type: "checkbox", checked: isPullDownOpen, onChange: () => {
                                setIsPullDownOpen(!isPullDownOpen);
                            } })] }) })), _jsx(FooterTextLink, { widgetType: widgetType, title: tvSeries, distributions: [] })] }));
};
const Wrapper = styled.div `
  max-width: 1080px;
  margin: 0 auto;

  * {
    box-sizing: border-box;
    word-wrap: break-word;
  }
`;
const FixedHeaderTable = styled.table `
  width: 100%;
  ${({ $isScrollable }) => {
    return ($isScrollable &&
        css `
        display: block;
        overflow-x: auto;
      `);
}};
  @media ${DEVICES.SMARTPHONE} {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
`;
const FixedTableHeader = styled.th `
  position: sticky;
  top: 0;
  color: #555;
  background: #dcf4f0;
  padding: 8px;
  font-size: 10px;
  line-height: 12px;
  min-width: 96px;
  z-index: 1;

  &:first-child {
    left: 0;
    z-index: 2;
    min-width: 126px;
    height: 42px;

    &::before {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      height: 100%;
      border: 1px solid #fff;
    }
  }
`;
const AccordionWrapper = styled.div `
  margin: 8px auto 18px;
`;
const AccordionCheckbox = styled.input `
  display: none;
`;
const AccordionLabel = styled.label `
  display: block;
  background: ${COLORS.LIGHTEST_GRAY};
  padding: 0.4rem 0.75rem;
  cursor: pointer;
  border-radius: 3px;
  width: 100%;
  text-align: center;
  font-size: 0.95rem;
`;
export { SeriesTable };
