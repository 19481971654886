import { parseISO } from 'date-fns';
import { Distribution } from '~/models/distribution';
import { Poster } from '~/models/poster';
import { URLS, CATEGORIES } from '~/constants';
const movieCategories = [CATEGORIES.LIVE_ACTION_MOVIE.SLUG, CATEGORIES.ANIME_MOVIE.SLUG];
class Movie {
    constructor(props) {
        this.getType = () => {
            return 'movie';
        };
        this.getName = () => {
            return this.japaneseName || `${this.originalName}(原題)`;
        };
        this.getNameWithBrackets = () => {
            return `『${this.getName()}』`;
        };
        this.getTitlePageUrl = () => {
            return `${URLS.PROD.ONESCREEN_WEB_APP}/movie/${this.id}`;
        };
        this.getPosterUrl = () => {
            const poster = this.posters.find((poster) => {
                return poster.country === 'JP';
            }) || this.posters[0];
            if (!poster) {
                return '';
            }
            return `${URLS.PROD.ONESCREEN_IMAGES}/${poster.path.startsWith('/') ? poster.path.slice(1) : poster.path}`;
        };
        this.getParentCategory = () => {
            if (this.category === CATEGORIES.LIVE_ACTION_MOVIE.SLUG) {
                return 'movie';
            }
            else {
                return 'anime';
            }
        };
        this.id = props.id;
        this.movieSeriesId = props.movieSeriesId || null;
        this.seriesNumber = props.seriesNumber || null;
        this.category = props.category;
        this.originalName = props.originalName;
        this.japaneseName = props.japaneseName || null;
        this.japaneseTagline = props.japaneseTagline || null;
        this.japaneseOverview = props.japaneseOverview || null;
        this.originalLanguage = props.originalLanguage;
        this.productionCountries = props.productionCountries;
        this.originalReleaseYear = props.originalReleaseYear || null;
        this.japaneseReleaseDate = props.japaneseReleaseDate ? parseISO(props.japaneseReleaseDate) : null;
        this.runningTime = props.runningTime;
        this.originalHomepageUrl = props.originalHomepageUrl || null;
        this.japaneseHomepageUrl = props.japaneseHomepageUrl || null;
        this.distributions =
            props.distributions?.map((childProps) => {
                return new Distribution(childProps);
            }) || [];
        this.posters =
            props.posters?.map((childProps) => {
                return new Poster(childProps);
            }) || [];
        this.genres = props.genres ?? [];
    }
}
export { Movie };
