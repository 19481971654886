class TvEpisode {
    constructor(props) {
        this.id = props.id;
        this.tvSeasonId = props.tvSeasonId;
        this.episodeNumber = props.episodeNumber;
        this.originalName = props.originalName;
        this.japaneseName = props.japaneseName || null;
        this.japaneseOverview = props.japaneseOverview || null;
        this.airDate = props.airDate || null;
        this.stillImageUrl = props.stillImageUrl || null;
    }
}
export { TvEpisode };
