const COLORS = {
    PURE_WHITE: '#fff',
    ULTRA_LIGHT_GRAY: '#f8f8f8',
    VERY_LIGHT_GRAY: '#f1f1f1',
    LIGHTEST_GRAY: '#eee',
    LIGHTER_GRAY: '#d7d7d7',
    LIGHT_GRAY: '#bcbcbc',
    MEDIUM_GRAY: '#808080',
    DARK_GRAY: '#7a7a7a',
    DARKEST_GRAY: '#aaa',
    SOFT_BLACK: '#555',
    STORM_DUST: '#666',
    MERCURY: '#e7e7e7',
    BLACK: '#1a1a1a',
    PALE_BLUE_GREEN: '#e8f8f6',
    BLUE_GREEN: '#14b59e',
    DEEP_BLUE_GREEN: '#12a994',
    DARK_BLUE_GREEN: '#0e8574',
    ALICE_BLUE: '#F9FDFF',
    BLACK_SQUEEZE: '#f4fcfb',
    ICEBERG: '#dcf4f0',
    ONAHAU_BLUE: '#D0F7FF',
    CHARLOTTE: '#BFEBF5',
    BONDI_BLUE: '#0D95B3',
    LIGHT_TURQUOISE: '#10C0E6',
    SECONDARY_DARK: '#0eadcf',
    SECONDARY_DARKEST: '#0a7991',
    CASAL: '#08596B',
    SHERBET_PINK: '#FF7EAC',
    FRENCH_ROSE: '#ee508e',
    ROSE_PINK: '#EB3B7A',
    ACCENT_LIGHT: '#fff1f6',
    ACCENT: '#eb3b7a',
    ACCENT_DARK: '#b42d5d',
    AMARANTH: '#f44860',
    CIATR_RED: '#f11a38',
    SHADOWY_RED: '#830214',
};
export { COLORS };
