import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { getAffiiateLink } from '~/usecases/link';
import { COLORS } from '~/constants';
import { LinkWithIcon } from '~/components/parts/linkWithIcon';
const MessageForRentableDistribution = (props) => {
    const { widgetType, title, seasonNumber, titleName, numberOfDistributions, vod, distribution } = props;
    const defaultPlan = vod.getMonthlyDefaultPlan();
    const linkUrl = getAffiiateLink(vod, distribution.titlePageUrl, window.location);
    return (_jsxs(NormalText, { children: [(() => {
                if (numberOfDistributions === 1) {
                    if (vod.id === 7) {
                        return (_jsxs(_Fragment, { children: [`${titleName}を視聴できるのは`, _jsxs(MarkerText, { children: [_jsx(LinkWithIcon, { widgetType: widgetType, href: linkUrl, text: vod.name, vod: vod, title: title, seasonNumber: seasonNumber }), "\u3060\u3051\uFF01"] })] }));
                    }
                    return (_jsxs(_Fragment, { children: [`${titleName}を視聴できる`, _jsxs(MarkerText, { children: ["\u552F\u4E00\u306E\u914D\u4FE1\u30B5\u30FC\u30D3\u30B9\u306F", _jsx(LinkWithIcon, { widgetType: widgetType, href: linkUrl, text: vod.name, vod: vod, title: title, seasonNumber: seasonNumber }), "\u3067\u3059\u3002"] })] }));
                }
                return (_jsxs(_Fragment, { children: [`${titleName}は${numberOfDistributions}サービスで配信されています。`, _jsxs(MarkerText, { children: ["\u305D\u306E\u4E2D\u30671\u756A\u304A\u3059\u3059\u3081\u306E\u30B5\u30FC\u30D3\u30B9\u306F", _jsx(LinkWithIcon, { widgetType: widgetType, href: linkUrl, text: vod.name, vod: vod, title: title, seasonNumber: seasonNumber }), "\u3067\u3059\u3002"] })] }));
            })(), _jsx("br", {}), _jsx("br", {}), (() => {
                if (vod.id === 6) {
                    return (_jsxs(_Fragment, { children: [`${vod.name}では、${defaultPlan.freeTrialPeriod}の無料期間中に取得可能なポイントで、`, _jsx(MarkerText, { children: "\u672C\u4F5C\u3092\u30EC\u30F3\u30BF\u30EB\u3057\u3066\u8996\u8074\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002" })] }));
                }
                if (vod.id === 7) {
                    return (_jsxs(_Fragment, { children: [`${defaultPlan.freeTrialPeriod}の無料トライアルがある${vod.name}では`, _jsx(MarkerText, { children: "\u5B85\u914D\u30EC\u30F3\u30BF\u30EB\u3092\u5229\u7528\u3059\u308B\u3053\u3068\u3067\u3001\u672C\u4F5C\u3092\u697D\u3057\u3080\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002" })] }));
                }
                if (defaultPlan.freeTrialPeriod && defaultPlan.initialPoint) {
                    return (_jsxs(_Fragment, { children: [`レンタル対象作品ですが、${defaultPlan.freeTrialPeriod}の無料トライアルでもらえる${defaultPlan.initialPoint}ポイントを使用すれば、 `, _jsx(MarkerText, { children: "\u672C\u4F5C\u3092\u5B9F\u8CEA\u7121\u6599\u3001\u307E\u305F\u306F\u304A\u5F97\u306B\u8996\u8074\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002" })] }));
                }
                if (defaultPlan.freeTrialPeriod) {
                    return (_jsxs(_Fragment, { children: [`レンタル対象作品ですが、${defaultPlan.freeTrialPeriod}の無料トライアルがある${vod.name}では、 `, _jsx(MarkerText, { children: "\u8FFD\u52A0\u6599\u91D1\u3092\u652F\u6255\u3046\u3053\u3068\u3067\u672C\u4F5C\u3092\u8996\u8074\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002" })] }));
                }
                return (_jsxs(_Fragment, { children: [`${vod.name}では`, _jsx(MarkerText, { children: "\u8FFD\u52A0\u6599\u91D1\u3092\u652F\u6255\u3046\u3053\u3068\u3067\u672C\u4F5C\u3092\u8996\u8074\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002" })] }));
            })()] }));
};
const NormalText = styled.p `
  font-size: 15.4px;
`;
const MarkerText = styled.span `
  background: linear-gradient(transparent 60%, ${COLORS.ONAHAU_BLUE} 0%);
  line-height: 25px;
  font-size: 15.4px;
  font-weight: bolder;
`;
export { MessageForRentableDistribution };
