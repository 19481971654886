import { createGlobalStyle } from 'styled-components';
import { ICOMOON } from '~/constants';
const GlobalStyle = createGlobalStyle `
  @font-face {
    font-family: 'icomoon';
    src:  url(${ICOMOON.EOT.URL});
    src:  url(${ICOMOON.EOT.URL}) format('embedded-opentype'),
    url(${ICOMOON.TTF.URL}) format('truetype'),
    url(${ICOMOON.WOFF.URL}) format('woff'),
    url(${ICOMOON.SVG.URL}) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  html {
    font-size: 87.5%;
    font-family: 游ゴシック, YuGothic, Hiragino Kaku Gothic ProN, Helvetica Neue, Helvetica, Arial, Meiryo, sans-serif;
    color: #555;
  }

  body {
    margin: 0;
    overflow: hidden;
  }
`;
export { GlobalStyle };
