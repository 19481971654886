import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from 'styled-components';
import { Movie } from '~/models';
import { getAffiiateLink } from '~/usecases/link';
import { canWatchFreely } from '~/usecases/check';
import { COLORS, IMAGES, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
const ButtonListItem = (props) => {
    const { title, vods, distribution, rank, widgetType, seasonNumber } = props;
    const vod = vods.find(({ id }) => {
        return id === distribution.vodId;
    });
    const defaultPlan = vod?.getMonthlyDefaultPlan();
    const linkUrl = useMemo(() => {
        if (!vod) {
            return '';
        }
        return getAffiiateLink(vod, distribution.titlePageUrl, window.location);
    }, [vod, distribution]);
    const labelText = useMemo(() => {
        if (distribution.isUnlimited) {
            return '見放題';
        }
        else if (distribution.isRentable) {
            return 'レンタル';
        }
        else if (distribution.isPurchasable) {
            return '購入';
        }
        else {
            return '';
        }
    }, [distribution]);
    const buttonText = useMemo(() => {
        const text = defaultPlan && canWatchFreely(distribution, defaultPlan) ? '無料体験で観る' : '今すぐ観る';
        return rank === 1 ? 'おすすめ！' + text : text;
    }, [distribution, defaultPlan, rank]);
    if (!vod) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(ListItem, { children: [_jsx(Link, { href: linkUrl, target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                    sendLinkClick(event.currentTarget.href, {
                        'widget_type': widgetType,
                        'content_id': generateContentIdParam(title, seasonNumber),
                        'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                        'category': findParentCategory(title.getParentCategory()).LABEL,
                        'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                        'genre': generateGenreParam(title.genres),
                        'vod_name': vod.name,
                    });
                }, children: _jsx(ServiceLogo, { src: vod.getSquareLogoImage(), alt: vod.name, width: 76, height: 76, onError: (event) => {
                        event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                    } }) }), _jsxs(ItemContent, { children: [_jsxs(ItemInfo, { children: [_jsx(VodName, { children: vod.name }), _jsxs(LabelList, { children: [labelText && _jsx(Label, { children: labelText }), defaultPlan?.hasFreeTrial() && _jsx(Label, { children: "\u7121\u6599\u671F\u9593\u3042\u308A" })] })] }), rank === 1 ? (_jsxs(RedButton, { href: linkUrl, target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                            sendLinkClick(event.currentTarget.href, {
                                'widget_type': widgetType,
                                'content_id': generateContentIdParam(title, seasonNumber),
                                'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                'category': findParentCategory(title.getParentCategory()).LABEL,
                                'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                'genre': generateGenreParam(title.genres),
                                'vod_name': vod.name,
                            });
                        }, children: [_jsx(ButtonText, { "$isRecommended": true, children: buttonText }), _jsx(ArrowIcon, { "$arrowColor": "white" })] })) : (_jsxs(WhiteButton, { href: linkUrl, target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                            sendLinkClick(event.currentTarget.href, {
                                'widget_type': widgetType,
                                'content_id': generateContentIdParam(title, seasonNumber),
                                'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                'category': findParentCategory(title.getParentCategory()).LABEL,
                                'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                'genre': generateGenreParam(title.genres),
                                'vod_name': vod.name,
                            });
                        }, children: [_jsx(ButtonText, { children: buttonText }), _jsx(ArrowIcon, { "$arrowColor": "red" })] }))] })] }));
};
const ListItem = styled.div `
  display: flex;
  height: 78px;
  gap: 12px;
`;
const ItemContent = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;
const ItemInfo = styled.div `
  display: flex;
  justify-content: space-between;
`;
const LabelList = styled.div `
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
/* eslint-disable indent */
const ArrowIcon = styled.div `
  border: 2px solid
    ${({ $arrowColor }) => {
    return $arrowColor === 'red' ? COLORS.CIATR_RED : COLORS.PURE_WHITE;
}};
  border-left: 0;
  border-top: 0;
  height: 8px;
  transform: rotate(-45deg);
  width: 8px;
  position: absolute;
  top: ${({ $arrowColor }) => {
    return $arrowColor === 'red' ? 10 : 12;
}}px;
  right: 20px;
`;
/* eslint-enable indent */
const RedButton = styled.a `
  background-color: ${COLORS.CIATR_RED};
  color: ${COLORS.PURE_WHITE};
  text-align: center;
  padding: 8px 0;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
  line-height: 20px;
  &:hover {
    opacity: 0.5;
  }
`;
const WhiteButton = styled.a `
  background-color: ${COLORS.PURE_WHITE};
  color: ${COLORS.CIATR_RED};
  text-align: center;
  padding: 6px 0;
  border-radius: 8px;
  text-decoration: none;
  position: relative;
  border: 2px ${COLORS.CIATR_RED} solid;
  box-sizing: border-box;
  line-height: 20px;
  &:hover {
    opacity: 0.5;
  }
`;
const Link = styled.a `
  transition: all 0.25s ease-out;
  &:hover {
    opacity: 0.5;
  }
`;
const VodName = styled.p `
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;
const Label = styled.p `
  border: 1px solid;
  padding: 0 4px;
  font-size: 10px;
  text-align: center;
  margin: 0;
  font-weight: bold;
  width: 60px;
`;
const ButtonText = styled.p `
  margin: 0;
  ${({ $isRecommended }) => {
    return $isRecommended ? `font-weight: bold;` : '';
}}
`;
const ServiceLogo = styled.img `
  width: 76px;
  height: 76px;
  object-fit: contain;
  border: 1px solid ${COLORS.LIGHT_GRAY};
  border-radius: 8px;
`;
export { ButtonListItem };
