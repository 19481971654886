class Poster {
    constructor(props) {
        this.id = props.id;
        this.country = props.country;
        this.path = props.path;
        this.width = props.width;
        this.height = props.height;
        this.copyright = props.copyright || null;
        this.note = props.note || null;
    }
}
export { Poster };
