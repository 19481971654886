import { useEffect, useState } from 'react';
const isUrl = (value) => {
    if (!value) {
        return false;
    }
    try {
        const url = new URL(value);
        return url.protocol === 'http:' || url.protocol === 'https:';
    }
    catch (_) {
        return false;
    }
};
const useOgp = (url) => {
    const [ogp, setOgp] = useState({ title: null, thumbnail: null });
    useEffect(() => {
        if (isUrl(url)) {
            fetch(url)
                .then((res) => {
                return res.text();
            })
                .then((text) => {
                const dom = new DOMParser().parseFromString(text, 'text/html');
                const headElements = dom.head.children;
                const titleElement = Array.from(headElements).find((element) => {
                    return element.tagName === 'META' && element.getAttribute('property') === 'og:title';
                });
                const thumbnailElement = Array.from(headElements).find((element) => {
                    return element.tagName === 'META' && element.getAttribute('property') === 'og:image';
                });
                setOgp({
                    title: titleElement ? titleElement.getAttribute('content') : null,
                    thumbnail: thumbnailElement ? thumbnailElement.getAttribute('content') : null,
                });
            })
                .catch((err) => {
                window.console.error(err);
            });
        }
    }, [url]);
    return ogp;
};
export { useOgp };
