import { formatInTimeZone } from 'date-fns-tz';
const TIME_ZONE = 'Asia/Tokyo';
const getDateAfterDays = (baseDate, days) => {
    const targetDate = new Date(baseDate.getTime());
    targetDate.setDate(targetDate.getDate() + days);
    return targetDate;
};
const getDateText = (date, format) => {
    return formatInTimeZone(date, TIME_ZONE, format);
};
export { getDateAfterDays, getDateText };
