import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as api from '~/api';
import { filterAvailableVods, filterDistributingVods } from '~/usecases/filter';
import { retrieveDistributions } from '~/usecases/retrieve';
import { sortVods } from '~/usecases/sort';
import { sendPageView, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
import { Iframe } from '~/components/parts/iframe';
import { SeriesTable } from '~/components/parts/seriesTable';
import { findParentCategory, findChildCategory } from '~/constants';
const Widget = (props) => {
    const { tvSeries, vods, widgetType } = props;
    const [isPullDownOpen, setIsPullDownOpen] = useState(false);
    const distributions = tvSeries.tvSeasons.map((tvSeason) => {
        return retrieveDistributions(tvSeries, tvSeason.seasonNumber);
    });
    const availableVods = sortVods(filterDistributingVods(filterAvailableVods(vods), distributions), distributions);
    useEffect(() => {
        sendPageView({
            'widget_type': widgetType,
            'content_id': generateContentIdParam(tvSeries, undefined),
            'content_name': tvSeries.getName(undefined),
            'category': findParentCategory(tvSeries.getParentCategory()).LABEL,
            'sub_category': findChildCategory(tvSeries.category, tvSeries.originalLanguage).LABEL,
            'genre': generateGenreParam(tvSeries.genres),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Iframe, { children: _jsx(SeriesTable, { widgetType: widgetType, tvSeries: tvSeries, vods: availableVods, isPullDownOpen: isPullDownOpen, setIsPullDownOpen: setIsPullDownOpen }) }));
};
const getProps = async (dataset) => {
    if (dataset.tvSeriesId && Number(dataset.tvSeriesId)) {
        return {
            widgetType: dataset.widgetType,
            tvSeries: await api.findTvSeries(Number(dataset.tvSeriesId)),
            vods: await api.getAllVodList(),
        };
    }
    throw new Error('TV series ID must be specified as number.');
};
export { Widget, getProps };
