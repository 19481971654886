import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { TvSeries } from '~/models';
import { IMAGES } from '~/constants';
const SorryBox = (props) => {
    const { title, seasonNumber } = props;
    const titleName = title instanceof TvSeries ? title.getNameWithBrackets(seasonNumber) : title.getNameWithBrackets();
    return (_jsxs(Wrapper, { children: [_jsx(BoldText, { children: `現在${titleName}を配信中のサービスはありません` }), _jsx(IconImage, { src: IMAGES.CRYING_BOY.URL, alt: IMAGES.CRYING_BOY.ALT, width: IMAGES.CRYING_BOY.WIDTH, height: IMAGES.CRYING_BOY.HEIGHT }), _jsx(NormalText, { children: "\u203B \u3053\u306E\u30C6\u30AD\u30B9\u30C8\u306F\u7BA1\u7406\u753B\u9762\u3067\u306E\u307F\u8868\u793A\u3055\u308C\u307E\u3059" })] }));
};
const Wrapper = styled.div `
  margin: 0 auto;
  padding: 1rem 1rem;
  max-width: 1080px;
  text-align: center;
`;
const BoldText = styled.p `
  margin: 0;
  padding: 4px 0;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.5;
`;
const NormalText = styled.p `
  margin: 0;
  padding: 4px 0;
`;
const IconImage = styled.img `
  height: 50px;
  width: 50px;
`;
export { SorryBox };
