import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { format } from 'date-fns';
import styled from 'styled-components';
import { COLORS } from '~/constants';
import { retrieveVodById } from '~/usecases/retrieve';
import { MessageForFreeDistribution } from './messageForFreeDistribution';
import { MessageForNoDistribution } from './messageForNoDistribution';
import { MessageForRentableDistribution } from './messageForRentableDistribution';
import { MessageForUnlimitedDistribution } from './messageForUnlimitedDistribution';
const ConclusionBox = (props) => {
    const { title, seasonNumber, distributions, vods, widgetType } = props;
    return (_jsxs(ConclusionBoxWrapper, { children: [_jsxs(MainContent, { children: [_jsx(ConclusionBoxHeader, { children: _jsx(ConclusionBoxTitle, { children: "\u7D50\u8AD6" }) }), _jsxs(ConclusionBoxContent, { children: [(() => {
                                const titleName = title.getNameWithBrackets(seasonNumber);
                                if (distributions.length === 0) {
                                    return _jsx(MessageForNoDistribution, { titleName: titleName });
                                }
                                const vod = retrieveVodById(vods, distributions[0].vodId);
                                if (distributions[0].isFree) {
                                    return (_jsx(MessageForFreeDistribution, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, titleName: titleName, distribution: distributions[0], numberOfDistributions: distributions.length, vod: vod }));
                                }
                                if (distributions[0].isUnlimited) {
                                    return (_jsx(MessageForUnlimitedDistribution, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, titleName: titleName, distribution: distributions[0], numberOfDistributions: distributions.length, vod: vod }));
                                }
                                return (_jsx(MessageForRentableDistribution, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, titleName: titleName, distribution: distributions[0], numberOfDistributions: distributions.length, vod: vod }));
                            })(), _jsx(AnnotationText, { children: `※配信状況は${format(new Date(), 'yyyy年M月d日')}時点のものです。` })] })] }), _jsx(CautionText, { children: "\u30CD\u30C3\u30C8\u4E0A\u306E\u9055\u6CD5\u52D5\u753B\u306E\u8996\u8074\u306F\u3001\u5229\u7528\u3057\u3066\u3044\u308B\u30C7\u30D0\u30A4\u30B9\u304C\u30A6\u30A4\u30EB\u30B9\u306B\u611F\u67D3\u3059\u308B\u6050\u308C\u3084\u3001\u6CD5\u7684\u63AA\u7F6E\u306E\u5BFE\u8C61\u306B\u306A\u308B\u53EF\u80FD\u6027\u304C\u3042\u308B\u305F\u3081\u4F7F\u7528\u3092\u3084\u3081\u307E\u3057\u3087\u3046\u3002" })] }));
};
const ConclusionBoxWrapper = styled.div `
  margin: 0 auto;
  padding: 8px 0;
  max-width: 1080px;
`;
const MainContent = styled.div `
  background: ${COLORS.ALICE_BLUE};
  margin-bottom: 24px;
`;
const ConclusionBoxHeader = styled.div `
  padding: 8px;
  text-align: left;
  -webkit-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${COLORS.LIGHT_TURQUOISE};
`;
const ConclusionBoxTitle = styled.h4 `
  color: ${COLORS.PURE_WHITE};
  font-size: 15.4px;
  font-weight: 700;
  margin: 0;
  padding-left: 4px;
`;
const ConclusionBoxContent = styled.div `
  padding: 18px;
  border: 3px solid ${COLORS.LIGHT_TURQUOISE};
  border-top: 0;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;
const AnnotationText = styled.p `
  font-size: 12px;
  color: ${COLORS.MEDIUM_GRAY};
  margin-top: 10px;
`;
const CautionText = styled.p `
  font-size: 12px;
  color: ${COLORS.MEDIUM_GRAY};
  position: relative;
  padding-left: 24px;
  &:before {
    content: '!';
    background: ${COLORS.MEDIUM_GRAY};
    color: ${COLORS.PURE_WHITE};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    line-height: 18px;
  }
`;
export { ConclusionBox };
