import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { getAffiiateLink } from '~/usecases/link';
import { retrieveVodById } from '~/usecases/retrieve';
import { COLORS, IMAGES, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
const TitleHeader = (props) => {
    const { vods, tvSeries, tvSeason, distribution, widgetType } = props;
    const seasonTitleName = tvSeason.getName()?.length < 16 ? tvSeason.getName() : `${tvSeason.getName().slice(0, 16)}...`;
    const posterUrl = tvSeason.getPosterUrl();
    const titleBody = (_jsxs(_Fragment, { children: [posterUrl && (_jsx(PosterImage, { src: posterUrl, alt: tvSeries.getNameWithBrackets(tvSeason.seasonNumber), width: 40, height: 58, onError: (event) => {
                    event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                } })), _jsx(Label, { children: seasonTitleName })] }));
    return (_jsx(FixedTableHeader, { children: distribution === undefined ? (titleBody) : (_jsx(Link, { href: getAffiiateLink(retrieveVodById(vods, distribution.vodId), distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                sendLinkClick(event.currentTarget.href, {
                    'widget_type': widgetType,
                    'content_id': generateContentIdParam(tvSeries, tvSeason.seasonNumber),
                    'content_name': tvSeries.getName(tvSeason.seasonNumber),
                    'category': findParentCategory(tvSeries.getParentCategory()).LABEL,
                    'sub_category': findChildCategory(tvSeries.category, tvSeries.originalLanguage).LABEL,
                    'genre': generateGenreParam(tvSeries.genres),
                    'vod_name': retrieveVodById(vods, distribution.vodId).name,
                });
            }, children: titleBody })) }, tvSeason.id));
};
const FixedTableHeader = styled.th `
  position: sticky;
  top: 0;
  color: ${COLORS.SOFT_BLACK};
  background: ${COLORS.ICEBERG};
  padding: 8px;
  font-size: 10px;
  line-height: 12px;
  min-width: 96px;
  z-index: 1;

  &:first-child {
    left: 0;
    z-index: 2;
    min-width: 126px;
    height: 42px;

    &:before {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      height: 100%;
      border: 1px solid ${COLORS.PURE_WHITE};
    }
  }
`;
const PosterImage = styled.img `
  width: 40px;
  height: 57.8px;
  object-fit: cover;
  margin-bottom: 4px;
`;
const Label = styled.p `
  margin: 0;
`;
const Link = styled.a `
  color: ${COLORS.DARK_BLUE_GREEN};
  display: block;
  text-decoration: none;
  transition: all 0.25s ease-out;
  &:hover {
    opacity: 0.5;
  }
`;
export { TitleHeader };
