import { retrieveVodById } from '~/usecases/retrieve';
const filterAvailableDistributions = (distributions, vods) => {
    const vodIds = vods.map((vod) => {
        return vod.id;
    });
    return distributions.filter((distribution) => {
        return vodIds.includes(distribution.vodId) && distribution.isAvailableNow();
    });
};
const filterUnlimitedDistributions = (distributions) => {
    return distributions.filter((distribution) => {
        return distribution.isUnlimited;
    });
};
const filterRentableDistributions = (distributions) => {
    return distributions.filter((distribution) => {
        return !distribution.isUnlimited && distribution.isRentable;
    });
};
const filterPointUsableDistributions = (distributions, vods) => {
    return distributions.filter((distribution) => {
        const vod = retrieveVodById(vods, distribution.vodId);
        const defaultPlan = vod.getMonthlyDefaultPlan();
        // Exceptionally, TSUTAYA DISCAS is grouped into point-usable distributions.
        return !!defaultPlan.initialPoint || vod.id === 7;
    });
};
const filterPointUnusableDistributions = (distributions, vods) => {
    return distributions.filter((distribution) => {
        const vod = retrieveVodById(vods, distribution.vodId);
        const defaultPlan = vod.getMonthlyDefaultPlan();
        return !defaultPlan.initialPoint && vod.id !== 7;
    });
};
const filterPurchasableDistributions = (distributions) => {
    return distributions.filter((distribution) => {
        return !distribution.isUnlimited && !distribution.isRentable && !!distribution.isPurchasable;
    });
};
const filterAvailableVods = (vods) => {
    return vods.filter((vod) => {
        return vod.state === 'connected';
    });
};
const filterUnavailableVods = (vods, distributions) => {
    const vodIds = distributions.map((distribution) => {
        return distribution.vodId;
    });
    return vods.filter((vod) => {
        return !vodIds.includes(vod.id);
    });
};
const filterDistributingVods = (vods, seriesDistributions) => {
    return vods.filter((vod) => {
        return seriesDistributions.some((distributions) => {
            const distribution = distributions.find(({ vodId }) => {
                return vodId === vod.id;
            });
            return distribution ? distribution.isAvailableNow() : false;
        });
    });
};
export { filterAvailableDistributions, filterUnlimitedDistributions, filterRentableDistributions, filterPointUsableDistributions, filterPointUnusableDistributions, filterPurchasableDistributions, filterAvailableVods, filterUnavailableVods, filterDistributingVods, };
