const sendPageView = (additionalParams) => {
    if (!window.gtag) {
        return;
    }
    window.gtag('event', 'page_view', {
        send_to: 'internal',
        sender: '1Screen Widget',
        ...additionalParams,
    });
};
const sendLinkClick = (url, additionalParams) => {
    if (!window.gtag) {
        return;
    }
    const baseParams = {
        sender: '1Screen Widget',
        outbound: new URL(url).host !== window.location.hostname,
        link_domain: new URL(url).host,
        link_url: url,
    };
    window.gtag('event', 'click', {
        send_to: 'internal',
        ...baseParams,
        ...additionalParams,
    });
    window.gtag('event', 'click', {
        send_to: 'external',
        ...baseParams,
    });
};
const generateContentIdParam = (title, seasonNumber) => {
    return `${title.getType()}-${title.id}` + (seasonNumber ? `-season-${seasonNumber}` : '');
};
const generateGenreParam = (genres) => {
    return genres
        .map((genre) => {
        return genre.japaneseName;
    })
        .join(',');
};
export { sendPageView, sendLinkClick, generateContentIdParam, generateGenreParam };
