import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { URLS, COLORS, IMAGES } from '~/constants';
import { TvSeries } from '~/models';
import { sendLinkClick } from '~/components/functions/analytics';
const PoweredByFooter = (props) => {
    const { widgetType, title, seasonNumber } = props;
    const linkUrl = title
        ? title instanceof TvSeries
            ? title.getTitlePageUrl(seasonNumber)
            : title.getTitlePageUrl()
        : URLS.PROD.ONESCREEN_WEB_APP;
    return (_jsx(FooterWrapper, { children: _jsxs(LogoWrapper, { href: linkUrl, target: "_blank", rel: "noopener", onClick: (event) => {
                sendLinkClick(event.currentTarget.href, { 'widget_type': widgetType });
            }, children: [_jsx(LogoLabel, { children: "Powered by" }), _jsx(LogoImage, { src: IMAGES.ONESCREEN_LOGO.URL, alt: IMAGES.ONESCREEN_LOGO.ALT, width: IMAGES.ONESCREEN_LOGO.WIDTH, height: IMAGES.ONESCREEN_LOGO.HEIGHT })] }) }));
};
const FooterWrapper = styled.div `
  text-align: right;
  margin-top: 5px;
`;
const LogoWrapper = styled.a `
  text-decoration: none;
  display: block;
  margin-top: 2px;
`;
const LogoLabel = styled.span `
  color: ${COLORS.MEDIUM_GRAY};
  font-size: 11px;
  margin-right: 4px;
`;
const LogoImage = styled.img `
  width: 90px;
  height: auto;
`;
export { PoweredByFooter };
