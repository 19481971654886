import { parseISO } from 'date-fns';
import { URLS } from '~/constants';
import { Distribution } from '~/models/distribution';
import { Poster } from '~/models/poster';
import { TvEpisode } from '~/models/tvEpisode';
class TvSeason {
    constructor(props) {
        this.getName = () => {
            return this.japaneseName || `シーズン${this.seasonNumber}`;
        };
        this.getPosterUrl = () => {
            const poster = this.posters.find((poster) => {
                return poster.country === 'JP';
            }) || this.posters[0];
            if (!poster) {
                return '';
            }
            return `${URLS.PROD.ONESCREEN_IMAGES}/${poster.path.startsWith('/') ? poster.path.slice(1) : poster.path}`;
        };
        this.id = props.id;
        this.tvSeriesId = props.tvSeriesId;
        this.seasonNumber = props.seasonNumber;
        this.originalName = props.originalName;
        this.japaneseName = props.japaneseName || null;
        this.japaneseOverview = props.japaneseOverview || null;
        this.firstAirDate = props.firstAirDate ? parseISO(props.firstAirDate) : null;
        this.tvEpisodes =
            props.tvEpisodes?.map((childProps) => {
                return new TvEpisode(childProps);
            }) || [];
        this.distributions =
            props.distributions?.map((childProps) => {
                return new Distribution(childProps);
            }) || [];
        this.posters =
            props.posters?.map((childProps) => {
                return new Poster(childProps);
            }) || [];
    }
}
export { TvSeason };
