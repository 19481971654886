import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import * as api from '~/api';
import { Iframe } from '~/components/parts/iframe';
import { PlayButtonList } from '~/components/parts/playButtonList';
import { PoweredByFooter } from '~/components/parts/poweredByFooter';
import { COLORS, DEVICES } from '~/constants';
import { TvSeries } from '~/models';
import { filterAvailableDistributions, filterAvailableVods, filterUnlimitedDistributions, filterRentableDistributions, filterPurchasableDistributions, } from '~/usecases/filter';
import { retrieveDistributions, retrieveVodById } from '~/usecases/retrieve';
import { PrimaryPlayButton } from '~/components/parts/primaryPlayButton';
import { canWatchFreely } from '~/usecases/check';
import { sortDistributions } from '~/usecases/sort';
const Widget = (props) => {
    const { widgetType, title, seasonNumber, vods } = props;
    if (!title) {
        return _jsx(_Fragment, {});
    }
    const availableVods = filterAvailableVods(vods);
    const distributions = retrieveDistributions(title, seasonNumber);
    const titleName = title instanceof TvSeries ? title.getNameWithBrackets(seasonNumber) : title.getNameWithBrackets();
    const availableDistributions = sortDistributions(filterAvailableDistributions(distributions, availableVods), availableVods);
    const startingSoonDistributions = distributions.filter((distribution) => {
        return distribution.isStartingSoon();
    });
    const getFeatureDistribution = () => {
        if (availableDistributions.length > 0) {
            return sortDistributions(availableDistributions, availableVods)[0];
        }
        else if (startingSoonDistributions.length > 0) {
            return sortDistributions(startingSoonDistributions, availableVods)[0];
        }
        return undefined;
    };
    const unlimitedDistributions = filterUnlimitedDistributions(availableDistributions);
    const rentableDistributions = filterRentableDistributions(availableDistributions);
    const purchasableDistributions = filterPurchasableDistributions(availableDistributions);
    const featureDistribution = getFeatureDistribution();
    const featureVod = featureDistribution && retrieveVodById(vods, featureDistribution.vodId);
    const defaultPlan = featureVod && featureVod.getMonthlyDefaultPlan();
    const freeTrialPeriod = defaultPlan && canWatchFreely(featureDistribution, defaultPlan) ? defaultPlan.freeTrialPeriod : undefined;
    const isStartingSoon = featureDistribution && featureDistribution.isStartingSoon();
    // Even if there is no availableDistributions, primaryButton is displayed if there is the distribution with isStartingSoon.
    if (!availableDistributions.length) {
        return (_jsx(Iframe, { children: _jsxs(Container, { children: [_jsx(Title, { children: `${titleName}の配信情報` }), featureDistribution && featureVod && isStartingSoon ? (_jsx(PrimaryPlayButton, { widgetType: widgetType, vod: featureVod, distribution: featureDistribution, displaySideLabel: !!freeTrialPeriod, isStartingSoon: isStartingSoon, freeTrialPeriod: freeTrialPeriod ?? undefined })) : (_jsxs(NotAvailableText, { children: ["\u73FE\u5728", titleName, "\u3092\u914D\u4FE1\u3057\u3066\u3044\u308B\u30B5\u30FC\u30D3\u30B9\u306F\u3042\u308A\u307E\u305B\u3093\u3002"] })), _jsx(PoweredByFooter, { widgetType: widgetType, title: title, seasonNumber: seasonNumber })] }) }));
    }
    return (_jsx(Iframe, { children: _jsxs(Container, { children: [_jsx(Title, { children: `${titleName}の配信情報` }), featureDistribution && featureVod && (_jsx(PrimaryPlayButton, { widgetType: widgetType, vod: featureVod, distribution: featureDistribution, displaySideLabel: !!freeTrialPeriod, isStartingSoon: false, freeTrialPeriod: freeTrialPeriod ?? undefined })), unlimitedDistributions.length > 0 && (_jsx(PlayButtonList, { label: "\u898B\u653E\u984C", widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: unlimitedDistributions, vods: vods })), rentableDistributions.length > 0 && (_jsx(PlayButtonList, { label: "\u30EC\u30F3\u30BF\u30EB", widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: rentableDistributions, vods: vods })), purchasableDistributions.length > 0 && (_jsx(PlayButtonList, { label: "\u8CFC\u5165", widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: purchasableDistributions, vods: vods })), _jsx(PoweredByFooter, { widgetType: widgetType, title: title, seasonNumber: seasonNumber })] }) }));
};
const getProps = async (dataset) => {
    const props = {
        widgetType: dataset.widgetType,
        vods: await api.getAllVodList(),
    };
    if (dataset.movieId && Number(dataset.movieId)) {
        return {
            ...props,
            title: await api.findMovie(Number(dataset.movieId)),
            seasonNumber: undefined,
        };
    }
    if (dataset.tvSeriesId && Number(dataset.tvSeriesId)) {
        if (dataset.seasonNumber && Number(dataset.seasonNumber)) {
            return {
                ...props,
                title: await api.findTvSeries(Number(dataset.tvSeriesId)),
                seasonNumber: Number(dataset.seasonNumber),
            };
        }
        throw new Error('Season number must be specified as number for TV series.');
    }
    if (dataset.cinemacafeMovieId && Number(dataset.cinemacafeMovieId)) {
        return {
            ...props,
            title: (await api.searchMovieList({ cinemacafeMovieId: Number(dataset.cinemacafeMovieId) }))[0],
            seasonNumber: undefined,
        };
    }
    if (dataset.natalieMovieId && Number(dataset.natalieMovieId)) {
        return {
            ...props,
            title: (await api.searchMovieList({ natalieMovieId: Number(dataset.natalieMovieId) }))[0],
            seasonNumber: undefined,
        };
    }
    if (dataset.natalieDramaId && Number(dataset.natalieDramaId)) {
        const tvSeries = (await api.searchTvSeriesList({ natalieDramaId: Number(dataset.natalieDramaId) }))[0];
        return {
            ...props,
            title: tvSeries,
            seasonNumber: tvSeries ? tvSeries.tvSeasons[0].seasonNumber : undefined,
        };
    }
    if (dataset.natalieAnimeId && Number(dataset.natalieAnimeId)) {
        const tvSeries = (await api.searchTvSeriesList({ natalieAnimeId: Number(dataset.natalieAnimeId) }))[0];
        return {
            ...props,
            title: tvSeries,
            seasonNumber: tvSeries ? tvSeries.tvSeasons[0].seasonNumber : undefined,
        };
    }
    throw new Error('Movie ID or TV series ID must be specified as number.');
};
const Container = styled('div') `
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${DEVICES.SMARTPHONE} {
    gap: 10px;
  }
`;
const Title = styled.h2 `
  color: black;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  margin: 0;
`;
const NotAvailableText = styled.span `
  color: ${COLORS.BLACK};
  font-size: 14px;
  line-height: 14px;
`;
export { Widget, getProps };
