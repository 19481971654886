import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
import { COLORS, IMAGES, findChildCategory, findParentCategory } from '~/constants';
import { Movie } from '~/models';
import { getAffiiateLink } from '~/usecases/link';
import { retrieveVodById } from '~/usecases/retrieve';
const LogoImageList = (props) => {
    const { widgetType, label, title, seasonNumber, distributions, vods } = props;
    return (_jsxs(ListWrapper, { children: [_jsx(ListLabel, { children: label }), _jsx(List, { children: distributions.map((distribution) => {
                    const vod = retrieveVodById(vods, distribution.vodId);
                    return (_jsx(LinkItem, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                            sendLinkClick(event.currentTarget.href, {
                                'widget_type': widgetType,
                                'content_id': generateContentIdParam(title, seasonNumber),
                                'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                'category': findParentCategory(title.getParentCategory()).LABEL,
                                'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                'genre': generateGenreParam(title.genres),
                                'vod_name': vod.name,
                            });
                        }, children: _jsx(LogoImage, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                                event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                            } }) }, distribution.id));
                }) })] }));
};
const ListWrapper = styled.div `
  background-color: ${COLORS.VERY_LIGHT_GRAY};
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const List = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
const LinkItem = styled.a `
  line-height: 0;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;
const ListLabel = styled.div `
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
`;
const LogoImage = styled.img `
  width: 70px;
  height: auto;
  border-radius: 4px;
`;
export { LogoImageList };
