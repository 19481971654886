import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import FrameComponent from 'react-frame-component';
import { Content } from './content';
const Iframe = (props) => {
    const { children } = props;
    const [iframeHeight, setIframeHeight] = useState(0);
    const iframeRef = useRef(null);
    return (_jsx(FrameComponent, { ref: iframeRef, title: "widget frame", head: _jsx("meta", { name: "viewport", content: "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" }), width: "100%", height: iframeHeight > 0 ? `${iframeHeight}px` : 'auto', style: { border: 'none' }, onLoad: () => {
            const height = iframeRef.current?.contentDocument?.body?.scrollHeight ?? 0;
            if (height !== 0) {
                setIframeHeight(height);
            }
        }, children: _jsx(Content, { ...props, setIframeHeight: setIframeHeight, children: children }) }));
};
export { Iframe };
