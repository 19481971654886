const canWatchFreely = (distribution, vodPlan) => {
    if (distribution.isFree || distribution.isPartiallyFree) {
        return true;
    }
    if ((vodPlan.isFree() || vodPlan.hasFreeTrial()) && distribution.isUnlimited) {
        return true;
    }
    return false;
};
const isMobile = () => {
    return !!window.matchMedia('(max-width: 768px)').matches;
};
export { canWatchFreely, isMobile };
