import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
import { COLORS, findChildCategory, findParentCategory } from '~/constants';
import { Movie, TvSeries } from '~/models';
import { getAffiiateLink } from '~/usecases/link';
import { retrieveDistributionsCountByVodId, retrieveEndingSoonText, retrieveVodById } from '~/usecases/retrieve';
import { InclusivePlayButton } from '~/components/parts/inclusivePlayButton';
import { isMobile } from '~/usecases/check';
const PlayButtonList = (props) => {
    const { widgetType, label, title, seasonNumber, distributions, vods } = props;
    return (_jsxs(ListWrapper, { children: [_jsx(ListLabel, { children: label }), _jsx(GridContainer, { "$isMobile": isMobile(), children: distributions.map((distribution) => {
                    const vod = retrieveVodById(vods, distribution.vodId);
                    const endingSoonText = retrieveEndingSoonText(distribution, retrieveVodById(vods, distribution.vodId), title);
                    return (_jsx(LinkItem, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                            sendLinkClick(event.currentTarget.href, {
                                'widget_type': widgetType,
                                'content_id': generateContentIdParam(title, seasonNumber),
                                'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                                'category': findParentCategory(title.getParentCategory()).LABEL,
                                'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                                'genre': generateGenreParam(title.genres),
                                'vod_name': vod.name,
                            });
                        }, children: _jsx(InclusivePlayButton, { widgetType: widgetType, vod: vod, headingText: vod.name, supplement: {
                                ongoing: title instanceof TvSeries
                                    ? `${String(retrieveDistributionsCountByVodId(title, distribution.vodId)) ?? ''}シーズン配信中`
                                    : '',
                                endingSoon: endingSoonText,
                            } }) }, distribution.id));
                }) })] }));
};
const ListWrapper = styled.div `
  background-color: ${COLORS.VERY_LIGHT_GRAY};
  border-radius: 7px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const LinkItem = styled.a `
  text-decoration: none;
  line-height: 0;
`;
const ListLabel = styled.div `
  color: ${COLORS.BLACK};
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
`;
const GridContainer = styled.div `
  display: grid;
  gap: 8px;
  grid-template-columns: ${({ $isMobile }) => {
    return $isMobile ? `1fr` : `1fr 1fr`;
}};
`;
export { PlayButtonList };
