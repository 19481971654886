import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Movie } from '~/models';
import { COLORS, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
const LinkWithIcon = (props) => {
    const { vod, title, text, href, seasonNumber, widgetType } = props;
    return (_jsx(LineFeedBlocker, { children: _jsx(Link, { target: "_blank", rel: "nofollow noopener", href: href, onClick: (event) => {
                sendLinkClick(event.currentTarget.href, {
                    'widget_type': widgetType,
                    'content_id': generateContentIdParam(title, seasonNumber),
                    'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                    'category': findParentCategory(title.getParentCategory()).LABEL,
                    'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                    'genre': generateGenreParam(title.genres),
                    'vod_name': vod.name,
                });
            }, children: text }) }));
};
const LineFeedBlocker = styled.span `
  display: inline-block;
`;
const Link = styled.a `
  position: relative;
  padding-left: 1.2rem;
  color: ${COLORS.BONDI_BLUE};
  font-weight: bold;
  text-decoration: underline;

  &::before {
    position: absolute;
    top: -4px;
    left: 0;
    display: inline-block;
    content: '\\e910';
    font-family: 'icomoon';
    font-size: 110%;
    text-decoration: none;
  }
`;
export { LinkWithIcon };
