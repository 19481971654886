import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from 'styled-components';
import { COLORS, IMAGES } from '~/constants';
const InclusivePlayButton = ({ vod, headingText, supplement }) => {
    return (_jsxs(ButtonItem, { children: [_jsx(VodLogoWrapper, { children: _jsx(LogoImage, { src: vod.getRectangleLogoImage(), alt: vod.name, width: 300, height: 200, onError: (event) => {
                        event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                    } }) }), _jsxs(ItemText, { children: [_jsx(HeadingText, { children: headingText }), supplement.ongoing && (_jsx(SupplementaryTextWrapper, { children: _jsx(OngoingText, { children: supplement.ongoing }) })), supplement.endingSoon && (_jsx(SupplementaryTextWrapper, { children: _jsx(EndingSoonText, { children: supplement.endingSoon }) }))] }), _jsx(PlayIcon, {})] }));
};
const HeadingText = styled.span `
  color: black;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
`;
const OngoingText = styled.span `
  color: ${COLORS.SOFT_BLACK};
  font-size: 12px;
  line-height: 12px;
`;
const EndingSoonText = styled.span `
  font-size: 12px;
  color: ${COLORS.ACCENT};
  line-height: 14px;
`;
const LogoImage = styled.img `
  width: 70px;
  height: auto;
  border-radius: 4px;
  border: solid 0.05rem ${COLORS.DARKEST_GRAY};
`;
const ButtonItem = styled.div `
  display: flex;
  align-items: center;
  line-height: 0;
  background-color: ${COLORS.PURE_WHITE};
  padding: 8px;
  border-radius: 3px;
`;
const ItemText = styled.div `
  flex-grow: 1;
  padding: 0 8px;
`;
const SupplementaryTextWrapper = styled.div `
  padding-top: 4px;
`;
const VodLogoWrapper = styled.div `
  flex-shrink: 0;
`;
const PlayIcon = styled.div `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${COLORS.ACCENT};
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent ${COLORS.PURE_WHITE};
    transform: translate(-40%, -50%);
`;
export { InclusivePlayButton };
