import { affiliateMedia } from '~/application/config';
import { retrieveVodById } from '~/usecases/retrieve';
const getFormatScore = (distribution, vod) => {
    const vodDefaultPlan = vod.getMonthlyDefaultPlan();
    if (distribution.isUnlimited) {
        return vodDefaultPlan.hasFreeTrial() ? 350 : 300;
    }
    if (distribution.isRentable) {
        return vodDefaultPlan.hasInitialPoint() ? 80 : 50;
    }
    if (distribution.isPurchasable) {
        return 10;
    }
    return 0;
};
const getSeriesFormatScore = (seriesDistributions, vod) => {
    const formatScore = seriesDistributions
        .map((distributions) => {
        const distribution = distributions.find(({ vodId }) => {
            return vodId === vod.id;
        });
        if (!distribution) {
            return 0;
        }
        return getFormatScore(distribution, vod);
    })
        .reduce((score1, score2) => {
        return score1 + score2;
    }, 0);
    return formatScore;
};
const sortDistributions = (distributions, vods) => {
    // We sort distributions by priority score that is described bellow.
    return distributions.sort((distribution1, distribution2) => {
        const vod1 = retrieveVodById(vods, distribution1.vodId);
        const vod2 = retrieveVodById(vods, distribution2.vodId);
        //
        const isAffiliateMedia = affiliateMedia.some((media) => {
            return media === window.location.hostname;
        });
        if (!isAffiliateMedia) {
            return vod1.priority - vod2.priority;
        }
        // Priority score for sorting is defined as format score + EPC + adjustment score.
        const formatScore1 = getFormatScore(distribution1, vod1);
        const formatScore2 = getFormatScore(distribution2, vod2);
        const priorityScore1 = formatScore1 + vod1.earnPerClick + (vod1.adjustmentScore || 0);
        const priorityScore2 = formatScore2 + vod2.earnPerClick + (vod2.adjustmentScore || 0);
        if (priorityScore1 !== priorityScore2) {
            return priorityScore2 - priorityScore1;
        }
        // If the priority scores of two distributions equals, sort by format score.
        if (formatScore1 !== formatScore2) {
            return formatScore2 - formatScore1;
        }
        // If the priority scores and format scores of them equals too, sort by EPC.
        return vod2.earnPerClick - vod1.earnPerClick;
    });
};
const sortVods = (vods, seriesDistributions) => {
    const formatScores = [];
    vods.forEach((vod) => {
        formatScores[vod.id] = getSeriesFormatScore(seriesDistributions, vod);
    });
    // Sort vods of tvSeries by priority score that is described bellow.
    return vods.sort((vod1, vod2) => {
        //
        const isAffiliateMedia = affiliateMedia.some((media) => {
            return media === window.location.hostname;
        });
        if (!isAffiliateMedia) {
            return vod1.priority - vod2.priority;
        }
        const formatScore1 = formatScores[vod1.id];
        const formatScore2 = formatScores[vod2.id];
        const priorityScore1 = formatScore1 + vod1.earnPerClick + (vod1.adjustmentScore || 0);
        const priorityScore2 = formatScore2 + vod2.earnPerClick + (vod2.adjustmentScore || 0);
        if (priorityScore1 !== priorityScore2) {
            return priorityScore2 - priorityScore1;
        }
        // If the priority scores of two distributions equals, sort by format score.
        if (formatScore1 !== formatScore2) {
            return formatScore2 - formatScore1;
        }
        // If the priority scores and format scores of them equals too, sort by EPC.
        return vod2.earnPerClick - vod1.earnPerClick;
    });
};
const sortTvSeasonAndDistributionPairs = (tvSeasonAndDistributionPairs, subject, sortBy) => {
    switch (subject) {
        case 'startsAt':
            return tvSeasonAndDistributionPairs.sort((a, b) => {
                if (sortBy === 'asc') {
                    return a.distribution.startsAt > b.distribution.startsAt ? 1 : -1;
                }
                return a.distribution.startsAt < b.distribution.startsAt ? 1 : -1;
            });
        case 'endsAt':
            return tvSeasonAndDistributionPairs.sort((a, b) => {
                if (sortBy === 'asc') {
                    return a.distribution.endsAt && b.distribution.endsAt && a.distribution.endsAt > b.distribution.endsAt
                        ? 1
                        : -1;
                }
                return a.distribution.endsAt && b.distribution.endsAt && a.distribution.endsAt < b.distribution.endsAt
                    ? 1
                    : -1;
            });
        case 'seasonNumber':
            return tvSeasonAndDistributionPairs.sort((a, b) => {
                if (sortBy === 'asc') {
                    return a.tvSeason.seasonNumber - b.tvSeason.seasonNumber;
                }
                return b.tvSeason.seasonNumber - a.tvSeason.seasonNumber;
            });
        default:
            return tvSeasonAndDistributionPairs;
    }
};
export { sortDistributions, sortVods, sortTvSeasonAndDistributionPairs };
