import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { Movie } from '~/models';
import { canWatchFreely } from '~/usecases/check';
import { getAffiiateLink } from '~/usecases/link';
import { retrieveVodById } from '~/usecases/retrieve';
import { COLORS, DEVICES, IMAGES, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
const NormalButton = (props) => {
    const { title, distribution, vods, widgetType, seasonNumber } = props;
    const vod = retrieveVodById(vods, distribution.vodId);
    const defaultPlan = vod.getMonthlyDefaultPlan();
    return (_jsx(ButtonWrapper, { children: _jsxs(RedButtonLink, { href: getAffiiateLink(vod, distribution.titlePageUrl, window.location), target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                sendLinkClick(event.currentTarget.href, {
                    'widget_type': widgetType,
                    'content_id': generateContentIdParam(title, seasonNumber),
                    'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                    'category': findParentCategory(title.getParentCategory()).LABEL,
                    'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                    'genre': generateGenreParam(title.genres),
                    'vod_name': vod.name,
                });
            }, children: [canWatchFreely(distribution, defaultPlan) ? `${vod.name}の無料体験で` : `今すぐ${vod.name}で`, "\u89B3\u308B"] }) }));
};
const ButtonWrapper = styled.div `
  text-align: center;
  padding: 8px 0;
`;
const RedButtonLink = styled.a `
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: ${COLORS.PURE_WHITE};
  background: ${COLORS.CIATR_RED};
  box-shadow: 0 4px 0 ${COLORS.SHADOWY_RED};
  border-radius: 8px;
  display: inline-block;
  min-width: 18rem;
  padding: 13px 31px;
  transition: all 0.25s ease-out;
  &:hover {
    color: ${COLORS.PURE_WHITE};
    opacity: 0.5;
  }
  &::after {
    content: '';
    display: inline-block;
    background-image: url('${IMAGES.RIGHT_ARROW.URL}');
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    margin: -2px 0 0 5px;
    background-size: contain;
    vertical-align: middle;
  }
  @media ${DEVICES.SMARTPHONE} {
    font-size: 14px;
    min-width: 15rem;
  }
`;
export { NormalButton };
