let apiKey = '';
let apiEndpoint = '';
const init = (config) => {
    if (!config.apiEndpoint) {
        window.console.log('API endpoint is not set.');
    }
    if (!config.apiKey) {
        window.console.log('API key is not set.');
    }
    apiEndpoint = config.apiEndpoint;
    apiKey = config.apiKey;
};
const urlJoin = (...paths) => {
    return paths
        .map((path, index) => {
        return index === 0 ? path.replace(/\/+$/, '') : path.replace(/^\/+/, '').replace(/\/+$/, '');
    })
        .join('/');
};
const request = async (path, options) => {
    return fetch(urlJoin(apiEndpoint, path), {
        ...options,
        headers: {
            'x-api-key': apiKey,
        },
    }).then((response) => {
        return response.json();
    });
};
export { init, request };
