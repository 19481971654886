import { apiKey, analyticsId } from '~/application/config';
import { render } from '~/application/handler';
(() => {
    switch (document.readyState) {
        case 'loading':
            document.addEventListener('DOMContentLoaded', render);
            break;
        case 'interactive':
        case 'complete':
            render();
            break;
        default:
            break;
    }
})();
export { render, apiKey, analyticsId };
