import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { getAffiiateLink } from '~/usecases/link';
import { COLORS, IMAGES, findChildCategory, findParentCategory } from '~/constants';
import { sendLinkClick, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
const VodHeader = (props) => {
    const { tvSeries, vod, rank, distribution, widgetType } = props;
    const defaultPlan = vod.getMonthlyDefaultPlan();
    const linkUrl = getAffiiateLink(vod, distribution?.titlePageUrl, window.location);
    return (_jsx(FixedTableHeader, { children: _jsxs(Grid, { children: [_jsxs(LogoBox, { children: [rank === 1 ? (_jsx(RecommendCrown, { src: IMAGES.RECOMMEND_CROWN.URL, alt: IMAGES.RECOMMEND_CROWN.ALT, width: IMAGES.RECOMMEND_CROWN.WIDTH, height: IMAGES.RECOMMEND_CROWN.HEIGHT })) : (_jsx(RankLabel, { children: rank })), _jsx(Link, { href: linkUrl, target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                sendLinkClick(event.currentTarget.href, {
                                    'widget_type': widgetType,
                                    'content_id': generateContentIdParam(tvSeries, undefined),
                                    'content_name': tvSeries.getName(undefined),
                                    'category': findParentCategory(tvSeries.getParentCategory()).LABEL,
                                    'sub_category': findChildCategory(tvSeries.category, tvSeries.originalLanguage).LABEL,
                                    'genre': generateGenreParam(tvSeries.genres),
                                    'vod_name': vod.name,
                                });
                            }, children: _jsx(ServiceLogo, { src: vod.getSquareLogoImage(), alt: vod.name, width: 46, height: 46, onError: (event) => {
                                    event.currentTarget.src = IMAGES.NO_IMAGE_RECTANGLE.URL;
                                }, "$isRecommended": rank === 1 }) })] }), _jsxs(GridItem, { children: [rank === 1 && _jsx(FeatureLabel, { children: "\uFF3C\u304A\u3059\u3059\u3081\uFF0F" }), (defaultPlan.isFree() || defaultPlan.hasFreeTrial()) && (_jsx(FeatureLabel, { children: `${defaultPlan.freeTrialPeriod || ''}無料` })), _jsx(ServiceButtonLink, { href: linkUrl, target: "_blank", rel: "nofollow noopener", onClick: (event) => {
                                sendLinkClick(event.currentTarget.href, {
                                    'widget_type': widgetType,
                                    'content_id': generateContentIdParam(tvSeries, undefined),
                                    'content_name': tvSeries.getName(undefined),
                                    'category': findParentCategory(tvSeries.getParentCategory()).LABEL,
                                    'sub_category': findChildCategory(tvSeries.category, tvSeries.originalLanguage).LABEL,
                                    'genre': generateGenreParam(tvSeries.genres),
                                    'vod_name': vod.name,
                                });
                            }, children: defaultPlan.isFree() || defaultPlan.hasFreeTrial() ? '無料で観る' : '今すぐ観る' })] })] }) }));
};
const FixedTableHeader = styled.th `
  position: sticky;
  top: 0;
  color: ${COLORS.SOFT_BLACK};
  background: ${COLORS.ICEBERG};
  padding: 8px;
  font-size: 10px;
  line-height: 12px;
  min-width: 96px;
  z-index: 1;

  &:first-child {
    left: 0;
    z-index: 2;
    min-width: 126px;
    height: 42px;

    &:before {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      height: 100%;
      border: 1px solid ${COLORS.PURE_WHITE};
    }
  }
`;
const Grid = styled.div `
  display: grid;
  grid-template-columns: 46px 1fr;
  column-gap: 4px;
`;
const LogoBox = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
const RecommendCrown = styled.img `
  width: 30px;
  height: auto;
`;
const RankLabel = styled.div `
  background: ${COLORS.MERCURY};
  color: ${COLORS.STORM_DUST};
  border-radius: 0 0 50% 0;
  font-size: 10px;
  font-weight: bold;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  line-height: 12px;
`;
const Link = styled.a `
  transition: all 0.25s ease-out;
  &:hover {
    opacity: 0.5;
  }
`;
const ServiceLogo = styled.img `
  width: 46px;
  height: 46px;
  object-fit: contain;
  border: ${({ $isRecommended }) => {
    return $isRecommended ? `1px solid ${COLORS.CIATR_RED}` : 0;
}};
`;
const GridItem = styled.div `
  align-self: center;
`;
const FeatureLabel = styled.p `
  margin: 0 0 6px;
  color: ${COLORS.DARK_BLUE_GREEN};
  font-size: 10px;
`;
const ServiceButtonLink = styled.a `
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 4px 0 ${COLORS.SHADOWY_RED};
  line-height: 1.5;
  color: ${COLORS.PURE_WHITE};
  font-weight: 600;
  background: ${COLORS.CIATR_RED};
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 10px;
  white-space: nowrap;
  transition: all 0.25s ease-out;
  &:hover {
    opacity: 0.5;
  }
`;
export { VodHeader };
