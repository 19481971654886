import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as api from '~/api';
import { Movie } from '~/models';
import { filterAvailableDistributions, filterAvailableVods } from '~/usecases/filter';
import { retrieveDistributions } from '~/usecases/retrieve';
import { sortDistributions } from '~/usecases/sort';
import { sendPageView, generateContentIdParam, generateGenreParam } from '~/components/functions/analytics';
import { EmphaticButton } from '~/components/parts/emphaticButton';
import { Iframe } from '~/components/parts/iframe';
import { SorryBox } from '~/components/parts/sorryBox';
import { isUsedInAdmin, findParentCategory, findChildCategory } from '~/constants';
import { FooterTextLink } from '~/components/parts/footerTextLink';
const Widget = (props) => {
    const { vods, title, seasonNumber, widgetType } = props;
    const distributions = retrieveDistributions(title, seasonNumber);
    const availableVods = filterAvailableVods(vods);
    const availableDistributions = sortDistributions(filterAvailableDistributions(distributions, availableVods), availableVods);
    useEffect(() => {
        sendPageView({
            'widget_type': widgetType,
            'content_id': generateContentIdParam(title, seasonNumber),
            'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
            'category': findParentCategory(title.getParentCategory()).LABEL,
            'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
            'genre': generateGenreParam(title.genres),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (availableDistributions.length > 0) {
        return (_jsxs(Iframe, { children: [_jsx(EmphaticButton, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, distribution: availableDistributions[0], vods: vods }), _jsx(FooterTextLink, { widgetType: widgetType, title: title, seasonNumber: seasonNumber, distributions: availableDistributions })] }));
    }
    if (isUsedInAdmin(window.location)) {
        return (_jsx(Iframe, { children: _jsx(SorryBox, { title: title, seasonNumber: seasonNumber }) }));
    }
    return null;
};
const getProps = async (dataset) => {
    if (dataset.movieId && Number(dataset.movieId)) {
        return {
            widgetType: dataset.widgetType,
            title: await api.findMovie(Number(dataset.movieId)),
            seasonNumber: undefined,
            vods: await api.getAllVodList(),
        };
    }
    if (dataset.tvSeriesId && Number(dataset.tvSeriesId)) {
        if (dataset.seasonNumber && Number(dataset.seasonNumber)) {
            return {
                widgetType: dataset.widgetType,
                title: await api.findTvSeries(Number(dataset.tvSeriesId)),
                seasonNumber: Number(dataset.seasonNumber),
                vods: await api.getAllVodList(),
            };
        }
        throw new Error('Season number must be specified as number for TV series.');
    }
    throw new Error('Movie ID or TV series ID must be specified as number.');
};
export { Widget, getProps };
