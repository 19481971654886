import { jsx as _jsx } from "react/jsx-runtime";
import { generateContentIdParam, generateGenreParam, sendLinkClick } from '~/components/functions/analytics';
import { COLORS, findChildCategory, findParentCategory } from '~/constants';
import styled from 'styled-components';
import { Movie, TvSeries } from '~/models';
const FooterTextLink = (props) => {
    const { title, seasonNumber, widgetType, distributions } = props;
    const titleName = title instanceof TvSeries ? title.getNameWithBrackets(seasonNumber) : title.getNameWithBrackets();
    return (_jsx(FooterTextLinkWrapper, { children: _jsx(StyledLink, { href: title instanceof TvSeries ? title.getTitlePageUrl(seasonNumber) : title.getTitlePageUrl(), target: "_blank", rel: "noopener", onClick: (event) => {
                sendLinkClick(event.currentTarget.href, {
                    'widget_type': widgetType,
                    'content_id': generateContentIdParam(title, seasonNumber),
                    'content_name': title instanceof Movie ? title.getName() : title.getName(seasonNumber),
                    'category': findParentCategory(title.getParentCategory()).LABEL,
                    'sub_category': findChildCategory(title.category, title.originalLanguage).LABEL,
                    'genre': generateGenreParam(title.genres),
                });
            }, children: distributions.length ? `${titleName}を配信中のサービスをもっと詳しく` : `${titleName}の配信状況をもっと詳しく` }) }));
};
const FooterTextLinkWrapper = styled.div `
  text-align: right;
  padding: 24px 18px 12px 0;
`;
const StyledLink = styled.a `
  text-decoration: underline;
  color: ${COLORS.DARK_GRAY};
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
`;
export { FooterTextLink };
