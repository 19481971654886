import { createElement } from 'react';
import * as AllInWidget from '~/components/widgets/allInWidget';
import * as ArticleWidget from '~/components/widgets/articleWidget';
import * as BalloonButtonWidget from '~/components/widgets/balloonButtonWidget';
import * as ConclusionWidget from '~/components/widgets/conclusionWidget';
import * as DistributionTableWidget from '~/components/widgets/distributionTableWidget';
import * as EmphaticButtonWidget from '~/components/widgets/emphaticButtonWidget';
import * as NormalButtonWidget from '~/components/widgets/normalButtonWidget';
import * as SeriesTableWidget from '~/components/widgets/seriesTableWidget';
import * as VodSummaryWidget from '~/components/widgets/vodSummaryWidget';
import * as SelectableButtonWidget from '~/components/widgets/selectableButtonWidget';
import * as ButtonListWidget from '~/components/widgets/buttonListWidget';
import * as LogoImageListWidget from '~/components/widgets/logoImageListWidget';
import * as PrimaryWidget from '~/components/widgets/primaryWidget';
const createComponent = async (dataset) => {
    switch (dataset.widgetType) {
        case 'normal-button-widget': {
            const props = await NormalButtonWidget.getProps(dataset);
            return createElement(NormalButtonWidget.Widget, props);
        }
        case 'balloon-button-widget': {
            const props = await BalloonButtonWidget.getProps(dataset);
            return createElement(BalloonButtonWidget.Widget, props);
        }
        case 'emphatic-button-widget': {
            const props = await EmphaticButtonWidget.getProps(dataset);
            return createElement(EmphaticButtonWidget.Widget, props);
        }
        case 'selectable-button-widget': {
            const props = await SelectableButtonWidget.getProps(dataset);
            return createElement(SelectableButtonWidget.Widget, props);
        }
        case 'distribution-table-widget': {
            const props = await DistributionTableWidget.getProps(dataset);
            return createElement(DistributionTableWidget.Widget, props);
        }
        case 'series-table-widget': {
            const props = await SeriesTableWidget.getProps(dataset);
            return createElement(SeriesTableWidget.Widget, props);
        }
        case 'vod-summary-widget': {
            const props = await VodSummaryWidget.getProps(dataset);
            return createElement(VodSummaryWidget.Widget, props);
        }
        case 'conclusion-widget': {
            const props = await ConclusionWidget.getProps(dataset);
            return createElement(ConclusionWidget.Widget, props);
        }
        case 'article-widget': {
            const props = await ArticleWidget.getProps(dataset);
            return createElement(ArticleWidget.Widget, props);
        }
        case 'all-in-widget': {
            const props = await AllInWidget.getProps(dataset);
            return createElement(AllInWidget.Widget, props);
        }
        case 'button-list-widget': {
            const props = await ButtonListWidget.getProps(dataset);
            return createElement(ButtonListWidget.Widget, props);
        }
        // Only used by Pintscope.
        case 'logo-image-list-widget': {
            const props = await LogoImageListWidget.getProps(dataset);
            return createElement(LogoImageListWidget.Widget, props);
        }
        // Only used by otomoshuma.com.
        case 'smart-link-widget': {
            const props = await PrimaryWidget.getProps(dataset);
            return createElement(PrimaryWidget.Widget, props);
        }
        // Only used by Cinemacafe and natalie.
        case 'distribution-list-widget': {
            const props = await PrimaryWidget.getProps(dataset);
            return createElement(PrimaryWidget.Widget, props);
        }
        default:
            return null;
    }
};
export { createComponent };
