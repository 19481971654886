import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as api from '~/api';
import { sendPageView } from '~/components/functions/analytics';
import { Iframe } from '~/components/parts/iframe';
import { VodSummary } from '~/components/parts/vodSummary';
const Widget = (props) => {
    const { vod, widgetType } = props;
    useEffect(() => {
        sendPageView({
            'widget_type': widgetType,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(Iframe, { children: _jsx(VodSummary, { widgetType: widgetType, vod: vod, hasAdditionalVodCaveats: true }) }));
};
const getProps = async (dataset) => {
    if (dataset.vodId && Number(dataset.vodId)) {
        return {
            widgetType: dataset.widgetType,
            vod: await api.findVod(Number(dataset.vodId)),
        };
    }
    throw new Error('VOD ID must be specified as number.');
};
export { Widget, getProps };
