import styled from 'styled-components';
import { DEVICES } from '~/constants';
const ServiceIntroduce = styled.div `
  max-width: 1080px;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-wrap: break-word;
  }
`;
const Heading = styled.h3 `
  padding: 8px 1px;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #d7d7d7;
  line-height: 1.4;
  font-feature-settings: 'palt';
  letter-spacing: 0.03em;

  @media ${DEVICES.SMARTPHONE} {
    font-size: 17px;
  }
`;
const Text = styled.p `
  font-size: 15.4px;
  line-height: 1.5;
  white-space: pre-wrap;

  &:not(:first-child) {
    margin-top: 24px;
  }
`;
const Mark = styled.em `
  background: linear-gradient(transparent 60%, #ffdfef 0%);
  line-height: 25px;
  font-size: 16px;
  font-weight: bolder;
  font-style: normal;
`;
const Small = styled.span `
  font-size: 11px;
`;
const Strong = styled.strong ``;
export { ServiceIntroduce, Heading, Text, Mark, Strong, Small };
