const URLS = {
    LOCAL: {},
    DEV: {
        CIATR_VIEW: 'https://matome-view.viviane-dev.com',
        CIATR_ADMIN: 'https://matome-admin.viviane-dev.com',
        ONESCREEN_WEB_APP: 'https://1screen.viviane-dev.com',
        ONESCREEN_IMAGES: 'https://images.1screen.viviane-dev.com',
    },
    PROD: {
        CIATR_VIEW: 'https://ciatr.jp',
        CIATR_ADMIN: 'https://matome-admin.ciatr.jp',
        ONESCREEN_WEB_APP: 'https://1screen.ciatr.jp',
        ONESCREEN_IMAGES: 'https://images.1screen.tv',
    },
    EXTERNAL: {},
};
const isUsedInAdmin = (location) => {
    return [URLS.DEV.CIATR_ADMIN, URLS.PROD.CIATR_ADMIN].includes(location.origin);
};
export { URLS, isUsedInAdmin };
