const install = (analyticsId) => {
    const isDownloaded = Array.from(document.head.getElementsByTagName('script')).some((script) => {
        return script.src.startsWith('https://www.googletagmanager.com/gtag/js');
    });
    if (!isDownloaded) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
        script.async = true;
        document.head.appendChild(script);
    }
};
const config = (internalAnalyticsId, externalAnalyticsId) => {
    const script = document.createElement('script');
    script.text = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }

    gtag('js', new Date());
    gtag('config', '${internalAnalyticsId}', { 'send_page_view': false, 'groups': 'internal' });
    gtag('config', '${externalAnalyticsId}', { 'send_page_view': false, 'groups': 'external' });
  `;
    document.head.appendChild(script);
};
const init = (internalAnalyticsId, externalAnalyticsId) => {
    install(internalAnalyticsId);
    config(internalAnalyticsId, externalAnalyticsId);
};
export { init };
